import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Transactions } from 'src/app/interfaces/transactions';
import { BudgetData } from 'src/app/interfaces/budgetData';
import { Subcategories } from 'src/app/interfaces/subcategories';
import { Month } from 'src/app/interfaces/month';

@Component({
  selector: 'app-budget-balance-totals',
  templateUrl: './budget-balance-totals.component.html',
  styleUrls: ['./budget-balance-totals.component.css']
})
export class BudgetBalanceTotalsComponent implements OnChanges {

  @Input() _type: string;
  @Input() transactions: Transactions[];
  @Input() budget_data: BudgetData[];
  @Input() subcategories: Subcategories[];
  @Input() subcategoryIDs: string;
  @Input() subcategoryID: number;
  @Input() active_dates: Month[];
  @Input() current_date: string;
  @Input() changeEvent: Month[];
  @Input() categoryID: number; 
  @Output() checkIfNegative = new EventEmitter<number>();

  public transactionalData: Object[] = [];
  public budgetData: Object[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.changeEvent !== undefined) {
      if(changes.changeEvent.currentValue !== changes.changeEvent.previousValue) {
        let active_dates = this.active_dates.map((dates) => { return { dates: dates.date } })
        this.transactionalData = []
        this.budgetData = []
        active_dates.forEach((date) => {
          this.compileTransactionData(date.dates, this._type)
          this.compileBudgetData(date.dates, this._type)
          if(this.changeEvent[date.dates])
            this.changeEvent[date.dates].onchange = false;
        })
      }
    }
    if(changes.current_date !== undefined) {
      if(changes.current_date.currentValue !== changes.current_date.previousValue) {
        this.transactionalData = []
        this.compileTransactionData(this.current_date, this._type)
        this.budgetData = []
        this.compileBudgetData(this.current_date, this._type)
      }
    }
    if(changes.transactions !== undefined) {
      if(changes.transactions.previousValue !== changes.transactions.currentValue) {
        this.transactionalData = []
        this.compileTransactionData(this.current_date, this._type)
      }
    }
    if(changes.budget_data !== undefined) {
      if(changes.budget_data.previousValue !== changes.budget_data.currentValue) {
        this.budgetData = []
        this.compileBudgetData(this.current_date, this._type)
      }
    } 
  }

  formatDate(date) {
    let splitDate = date.split('/')
    let formattedDate = (splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]) + '-' + splitDate[2];
   
    return formattedDate;
  }

  filterTransactionsByDate(date) {
    return this.transactions.filter((transaction) => this.formatDate(transaction.date) === date)
  }

  filterBudgetDataByDate(date) {
    return this.budget_data.filter((budget_data) => budget_data.date === date)
  }

  backdateTransactions(date) {
    return this.transactions.filter((transaction) => {
      return ((+transaction.date.split('/')[2] < +date.split('-')[1]) || 
        +transaction.date.split('/')[2] === +date.split('-')[1] && 
        +transaction.date.split('/')[0] <= +date.split('-')[0])
    }).map((transaction) => {
      return {
        in: transaction.in,
        out: transaction.out,
        subcategories_id: transaction.subcategories_id
      }
    })
  }

  backdateBudgetData(date)  {
    return this.budget_data.
      filter((budgetData) => 
        (budgetData.date.split('-')[1] < date.split('-')[1]) || 
        (budgetData.date.split('-')[1] === date.split('-')[1] && 
         budgetData.date.split('-')[0] <= date.split('-')[0])).map(({date, amount, subcategories_id, categories_id}) => {
          return {
            date: date,
            amount: amount,
            subcategories_id: subcategories_id,
            categories_id: categories_id
          }
         })
  }

  compileTransactionData(date, type) {
    const transactionData = this.backdateTransactions(date);

    if(this.transactionalData[date] === undefined) {
      this.transactionalData[date] = []
    }

    if(type === 'subcategory') {
      transactionData.map((t) => {
        if(this.transactionalData[date][t.subcategories_id] === undefined) {
          this.transactionalData[date][t.subcategories_id] = 0;
        }      
  
        if(t.in !== null) {
          return this.transactionalData[date][t.subcategories_id] += t.in;
        } else {
          return this.transactionalData[date][t.subcategories_id] -= t.out;
        }
      })
    } else {
      if(this.subcategoryIDs === null)
        return;

      this.subcategoryIDs.split(';').forEach((ID) => {
        if(ID === '')
          return;
        
        transactionData.map((t) => {
          if(this.transactionalData[date][this.categoryID] === undefined) {
            this.transactionalData[date][this.categoryID] = 0;
          }

          if(+ID === t.subcategories_id) {
            if(t.in !== null) {
              this.transactionalData[date][this.categoryID] += t.in
            } else {
              this.transactionalData[date][this.categoryID] -= t.out
            }
          }
        })
      })
    }
    
  }

  compileBudgetData(date, type) {
    const budgetData = this.backdateBudgetData(date);

    if(this.budgetData[date] === undefined) {
      this.budgetData[date] = []
    }

    if(type === 'subcategory') {
      budgetData.map((b) => {
        if(this.budgetData[date][b.subcategories_id] === undefined) {
          this.budgetData[date][b.subcategories_id] = 0;
        }
  
        return this.budgetData[date][b.subcategories_id] += b.amount
      })
    } else {
      if(this.subcategoryIDs === null)
        return;

      this.subcategoryIDs.split(';').forEach((ID) => {
        if(ID === '')
          return;
        
        budgetData.map((b) => {
          if(this.budgetData[date][b.categories_id] === undefined) {
            this.budgetData[date][b.categories_id] = 0;
          }

          if(+ID === b.subcategories_id) {
            return this.budgetData[date][b.categories_id] += b.amount
          }
        })
      })
    }
  }

}
