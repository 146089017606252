import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { Transactions } from 'src/app/interfaces/transactions';
import { map, take} from 'rxjs/operators';
import { BudgetService } from 'src/app/services/budgets/budget.service';

@Component({
  selector: 'app-budget-transaction-totals',
  templateUrl: './budget-transaction-totals.component.html',
  styleUrls: ['./budget-transaction-totals.component.css'],

})
export class BudgetTransactionTotalsComponent implements OnChanges {
  @Input() _type: string;
  @Input() subcategoryIDs: string;
  @Input() subcategoryID: number;
  @Input() months: string;
  @Input() transactions: Transactions[];

  transaction_totals: number[] = [];

  constructor(public transactionsService: TransactionsService, public budgetService: BudgetService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.subcategoryIDs !== undefined) {
      if(changes.subcategoryIDs.previousValue !== changes.subcategoryIDs.currentValue) {
        this.transaction_totals = []
        this.generateTransactionsTotals(this._type);
      }
    }
    if(changes.transactions !== undefined) {
      if(changes.transactions.previousValue !== changes.transactions.currentValue) {
        this.transaction_totals = []
        this.generateTransactionsTotals(this._type);
      }
    }
    if(changes.months !== undefined) {
      if(changes.months.currentValue !== changes.months.previousValue) {
        this.transaction_totals = []
        this.generateTransactionsTotals(this._type);
      }
    }
  }

  filterBySubcategoryID(id?) {
    return this.transactionsService.filterByDate(this.months).pipe(
      take(1),
      map((t) => {
        return t.filter((t) => t.subcategories_id === ((id === undefined) ? this.subcategoryID : id))
      })
    )
  }

  generateTransactionsTotals(type) {
    if(this.transaction_totals[this.months] === undefined) {
      this.transaction_totals[this.months] = 0
    }

    switch(type) {
      case 'subcategory':
        this.filterBySubcategoryID().pipe(
          //take(1)
        ).subscribe((t) => {
          t.forEach((t) => {
            if(t.in !== null) {
              this.transaction_totals[this.months] += +t.in
            } else {
              this.transaction_totals[this.months] -= +t.out
            }
          })
        });  
        break;
      case 'category':
        if(this.subcategoryIDs === null)
          return;

        this.subcategoryIDs.split(';').forEach((subcategoryID) => {
          if(subcategoryID === '') {
            this.transaction_totals[this.months] = 0;
          }

          this.filterBySubcategoryID(+subcategoryID).pipe(
            //take(1)
          ).subscribe((t) => {
            t.forEach((t) => {
              if(t.in !== null) {
                this.transaction_totals[this.months] += +t.in
              } else {
                this.transaction_totals[this.months] -= +t.out
              }
            })
          })
        })
        break;
      default:
        break;
    } 
  }
}
