import { Component, OnInit } from '@angular/core';
import { AccountsComponent } from 'src/app/core/accounts/accounts.component';
import { Transactions } from 'src/app/interfaces/transactions';
import { Observable } from 'rxjs';
import { Accounts } from 'src/app/interfaces/accounts';

@Component({
  selector: 'app-core-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent extends AccountsComponent implements OnInit { 
  public currentYear: number = new Date().getUTCFullYear();
  public innerWidth: number;
  public accountsObs: Observable<Accounts[]>;
  public transactionsObs: Observable<Transactions[]>;
  public totalsObs: Observable<{}>;

  ngOnInit() {
    this.accountsObs = this.accountsService.accountsObs;
    this.transactionsObs = this.transactionsService.transactionsObs;
    this.totalsObs = this.transactionsService.totalsObs;
  }

  getClientWidth() {
    return window.innerWidth;
  }

  checkClientWidth() {
    if (this.getClientWidth() <= 1279) {
      document.querySelector("aside").setAttribute('data-toggle', 'push-menu')
      return true;
    } else {
      document.querySelector("aside").removeAttribute('data-toggle')
      return false;
    }
  } 


}
