import { Component, OnInit, SimpleChanges, OnChanges, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { UserService } from 'src/app/services/users/user.service';
import { BudgetService } from 'src/app/services/budgets/budget.service';
import { ActivatedRoute } from '@angular/router';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { AccountService } from 'src/app/services/accounts/account.service';
import { CurrencyPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Transactions } from 'src/app/interfaces/transactions';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css'],
  providers: [CurrencyPipe]
})
export class AccountsComponent implements OnInit, OnChanges {
  public newAccountForm: FormGroup;
  public editAccountForm: FormGroup;
  public newTransactionForm: FormGroup;
  public currentDate: string = new Date().toLocaleDateString();
  public activeAccountID: number;
  public isDataLoaded: boolean = false;
  public editing: Boolean = false;
  public transactions: Transactions[] = [];

  constructor(public formbuilder: FormBuilder, public title: Title, public route: ActivatedRoute, 
              public accountsService: AccountService, public transactionsService: TransactionsService, 
              public userService: UserService, public budgetService: BudgetService, public currencyPipe: CurrencyPipe,public renderer: Renderer2) {}

  ngOnInit() {
    this.accountsService.updateAccountsObject('init')
    this.transactionsService.updateTransactionsObject('init')
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
  }

  getUser() {
    return this.userService.user;
  }

  getClientHeight() {
    return window.innerHeight;
  }

  getClientWidth() {
    return window.innerWidth;
  }

  formatToCurrency(input) {
    return (input !== null) ? this.currencyPipe.transform(input, "USD", "symbol-narrow").toString() : '';
  }

}
