import { Component, OnInit, OnDestroy, Input, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../../services/users/user.service';
import { User } from '../../interfaces/user';
import { BudgetService } from '../../services/budgets/budget.service';
import { Budgets } from '../../interfaces/budgets';
import { CurrencyPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { AccountService } from 'src/app/services/accounts/account.service';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css'],
  providers: [CurrencyPipe]
})
export class BudgetComponent implements OnDestroy, OnInit {

  private ngUnsubscribe: Subject<string> = new Subject();
  public isDataLoaded = false; // aesthetic way of holding back content before data has fully loaded.
  public budgets: Budgets[] = [new Budgets()]; // create new Budgets to prevent undefined errors
  public favorite_budget: Budgets = new Budgets();
  public default_budget: Budgets = new Budgets();
  public user: User;

  constructor(private title: Title, private userService: UserService, public budgetService: BudgetService, 
    public currencyPipe: CurrencyPipe, public transactionsService: TransactionsService, public accountsService: AccountService, public renderer: Renderer2) {}

  getClientHeight() {
    return window.innerHeight;
  }

  budgetsExist() {
    return this.budgets.length !== 0;
  }

  hasDefault() {
    return this.user.default_budget_id;
  }

  setDefault() {
    return this.budgetService.setDefault(this.default_budget.id, this.user.id)
  }

  getUser() {
    return this.userService._getUser();
  }

  /**
   * Setup this.user & this.budgets with variables needed to run;
   * #2 * calls budgetService within subscription of userService to retrive userID
   */
  private _init() {
    this.userService.getUser().subscribe(
      (_user: any) => {
        this.userService.setUser(_user)
        this.user = _user.body.data
        this.budgetService.getBudgets(_user.body.data.id).subscribe(
          (_budget: Budgets[]) => {
            this.budgets = _budget;
            this.favorite_budget = _budget[_user.body.data.default_budget_id]
            this.default_budget = _budget[0];
            this.isDataLoaded = true;
          })
      }
    );
  }

  ngOnInit() {
    this._init();
    this.title.setTitle('MrBudget –– Your Budget')
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
