import { Injectable, HostListener, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router'
import { UserService } from '../users/user.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, share, map, take } from 'rxjs/operators';
import { BudgetData } from 'src/app/interfaces/budgetData';
import { Subcategories } from 'src/app/interfaces/subcategories';
import { Categories } from 'src/app/interfaces/categories';

@Injectable()
export class BudgetService {
  @HostListener('window:resize', ['$event'])
  private url: string = 'https://api.mrbudget.io';
  private sub_url: string = '/api/v1'
  private base_url: string = this.url + this.sub_url;
  private headers: HttpHeaders = new HttpHeaders({ 'content-type': 'application/json', 'accept': 'application/json' }); 
  date = new Date();

  public subcategoriesSource: BehaviorSubject<Subcategories[]> = new BehaviorSubject<Subcategories[]>([]);
  public subcategoriesObs: Observable<Subcategories[]> = this.subcategoriesSource.asObservable();
  private subcategories: Subcategories[];
  
  public categoriesSource: BehaviorSubject<Categories[]> = new BehaviorSubject<Categories[]>([]);
  public categoriesObs: Observable<Categories[]> = this.categoriesSource.asObservable();
  private categories: Categories[];

  public budgetDataSource: BehaviorSubject<BudgetData[]> = new BehaviorSubject<BudgetData[]>([]);
  public budgetDataObs: Observable<BudgetData[]> = this.budgetDataSource.asObservable();
  private budgetData: BudgetData[];

  constructor(private http: HttpClient, private userService: UserService) {}

  onResize(event) {
    return event.target.innerWidth;
  }

  updateCategoriesObject(type, val?) {
    switch(type) {
      case('init'):
        this.getCategories().pipe(
          take(1),
          map((categories: Categories[]) => {
            this.categoriesSource.next(categories)
          })
        ).subscribe();
        break;
      case('one'):
        this.categories.push(val)
        this.categoriesSource.next(val)
        break;
    }
  }

  updateSubcategoriesObject(type, val?) {
    switch(type) {
      case('init'):
        this.getSubcategories().pipe(
          take(1),
          map((subcategories: Subcategories[]) => {
            this.subcategoriesSource.next(subcategories)
          })
        ).subscribe();
        break;
      case('one'):
        this.subcategories.push(val)
        this.subcategoriesSource.next(val)
        break;
    }
  }


  // change this to accomodate updating single value into array without API call
  updateBudgetDataObject(type?, val?) {
    switch(type) {
      case('new'):
        this.budgetData.push(val)
        this.budgetDataSource.next(this.budgetData)
        break;
      default:
        this.getAllBudgetData().pipe(
          take(1),
          map((budgetData: BudgetData[]) => {
            this.budgetDataSource.next(budgetData)
            this.budgetData = budgetData;
          })
        ).subscribe();
    }
  }

  public subFromCategoriesID(id) {
    return this.subcategoriesObs.pipe(
      //take(1),
      map((t) => {
        return t.filter((tt) => tt.categories_id === id && tt.hidden === false)
      })
    )
  }

  public createBudget(data) {
    let url = this.base_url + '/budgets';

    return this.http.post(url, data, { headers: this.headers }).pipe(share());
  }

  public getBudgets(user_id) {
    let url = this.base_url + '/budgets/count/' + user_id;

    return this.http.get(url, { headers: this.headers }).pipe(share());
  }

  public updateBudget(id, data) {
    let url = this.base_url + '/budgets/' + id;

    return this.http.put(url, data, { headers: this.headers }).pipe(share());
  }

  public budgetsExist() {
    let url: string = this.base_url + '/budgets/count/' + this.userService._getUser().id;

    return this.http.get(url, { headers: this.headers }).pipe(share());
  }

  public setDefault(budget_id, user_id): any {
    let url = this.url + '/auth';

    return this.http.put(url, { id: user_id, default_budget_id: budget_id }, { headers: this.headers }).pipe(share());
  }

  /**
   * 
   * @param name CATEGORIES
   */
  public addCategory(data) {
    let url = this.base_url + '/categories/';

    return this.http.post(url, data, { headers: this.headers }).pipe(share());
  }

  public updateCategory(id, data) {
    let url = this.base_url + '/categories/' + id;

    return this.http.put(url, data, { headers: this.headers }).pipe(share());
  }

  public deleteCategory(id) {
    let url = this.base_url + '/categories/' + id;

    return this.http.delete(url, { headers: this.headers }).pipe(share());
  }

  public getCategories() {
    let url = this.base_url + '/categories/';

    return this.http.get(url, { headers: this.headers }).pipe(share());
  }

  public getCategory(id) {
    let url = this.base_url + '/categories/' + id;

    return this.http.get(url, { headers: this.headers }).pipe(share());
  }

  /**
   * 
   * @param name SUBCATEGORIES
   */

  public addSubcategory(data) {
    let url = this.base_url + '/subcategories/';    

    return this.http.post(url, data, { headers: this.headers }).pipe(share());
  }

  public updateSubcategory(id, data) {
    let url = this.base_url + '/subcategories/' + id;

    return this.http.put(url, data, { headers: this.headers }).pipe(share());
  }

  public deleteSubcategory(id) {
    let url = this.base_url + '/subcategories/' + id;

    return this.http.delete(url, { headers: this.headers }).pipe(share());
  }

  public getSubcategories() {
    let url = this.base_url + '/subcategories/';

    return this.http.get(url, { headers: this.headers }).pipe(share());
  }

  public getSubcategory(id) {
    let url = this.base_url + '/subcategories/' + id;

    return this.http.get(url, { headers: this.headers }).pipe(share());
  }

  /**
   * @param name BUDGET_DATA
   */

  public updateBudgetData(data, id) {
    let url: string = this.base_url + '/budget_data/' + id;

    return this.http.put(url, data, { headers: this.headers }).pipe(share());
  }

  public addBudgetData(data) {
    let url = this.base_url + '/budget_data/';

    return this.http.post(url, data, { headers: this.headers }).pipe(share());
  }

  public getAllBudgetData() {
    let url: string = this.base_url + '/budget_data/';

    return this.http.get(url, { headers: this.headers }).pipe(share());
  }

  public filterByDate(date) {
    return this.budgetDataObs.pipe(
      map((budgetData) => {
        return budgetData.filter((data) => data.date === date)
      })
    )
  }


  public getBudgetData(month): Observable<BudgetData[]> {
    let url: string = this.base_url + '/budget_data/month/' + month;

    return this.http.get<BudgetData[]>(url, { headers: this.headers }).pipe(share());
  }

  public saveActiveMonthsInView(dates) {
    dates.forEach(_date => {
      _date.date.split('-');
    });
  }

  /**
   *
   * Loads the currently active months and strips the month, and year from the array. 
   * uses the month and year to add the future or past month not currently loaded into view. 
   *
   * Object preview: { month: '', year: ''}
   *
   */

  public modifyBudgetDates(toggle, date, activeMonths) {
    const months = activeMonths;
    const newDateArr: any[] = [];
    let newYear, newMonth;
    let i = 0;
    let d: any;

    activeMonths.forEach(el => {
      newDateArr[i] = { month: el.date.split('-')[0], year: el.date.split('-')[1] };
      i += 1;
    });

    switch (toggle) {
      case('last'):
        const oldMonthLast = newDateArr[0].month;
        const oldYearLast = newDateArr[0].year;
        if (oldMonthLast === '01') {
            newYear = oldYearLast;
            newYear--;
            newMonth = 12;
        } else {
          newYear = oldYearLast;
          newMonth = oldMonthLast;
          newMonth--;
        }
        d = { month: newMonth.toString(), year: newYear.toString() };
        newDateArr.unshift(d);
        newDateArr.splice(newDateArr.length - 1);
        break;
      case('next'):
        const oldMonthNext = newDateArr[months.length - 1].month;
        const oldYearNext = newDateArr[months.length - 1].year;
        if (oldMonthNext === '12') {
            newYear = oldYearNext;
            newYear++;
            newMonth = 1;
        } else {
          newYear = oldYearNext;
          newMonth = oldMonthNext;
          newMonth++;
        }
        d = { month: newMonth.toString(), year: newYear.toString() };
        newDateArr.push(d);
        newDateArr.reverse().splice(newDateArr.length - 1);
        newDateArr.reverse();
        break;
      default:
        console.log('error1');
    }

    return newDateArr;
  }

  /**
   *
   * Generate front load of budget months based on the current month plus two months into the future,
   * user will decide if they need to go back, months will be loaded into data on the fly, to reduce memory load.
   *
   */

  public generateBudgetDates() {
    const date = new Date();
    const start = date.getMonth() + 1;
    const stop = date.getMonth() + 3;
    const year = date.getFullYear();
    let control = date.getMonth();
    const final: any = [];
    let y = 0;

    for (let i = start; i < stop+1; i++) {
      if (control === 12) {
        control = 0;
        this.date.setFullYear(year + 1);
      }
      this.date.setMonth(control++);

      final[y] = { month: (this.date.getMonth()).toString(), year: this.date.getFullYear().toString() };

      y++;
    }

    return final;
  }

}

@Injectable()
export class ReqInterceptor implements HttpInterceptor {

    constructor(private userService: UserService, private router: Router) { }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        let is_valid: boolean = localStorage.getItem('access-token') != null && localStorage.getItem('uid') != null && localStorage.getItem('client') != null;
        let headers = new HttpHeaders();
        let res;

        if(is_valid && (req.headers.get('access-token') !== localStorage.getItem('access-token'))) {
          req = req.clone({ setHeaders: {
            'uid': localStorage.getItem('uid'),
            'access-token': localStorage.getItem('access-token'),
            'client': localStorage.getItem('client')
          }});
        }

        if (!req.headers.has('Content-Type')) {
          req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

        return next.handle(req).pipe(tap(
          (event) => {
            if (event instanceof HttpResponse) {
              if (event.headers.get('access-token') !== req.headers.get('acess-token') && event.headers.get('access-token') !== '') {
                localStorage.setItem('access-token', event.headers.get('access-token'))
              }
            }
          },
          (err) => {
            //console.log(err)
            if(err instanceof HttpErrorResponse) {
              if(err.status === 401 || err.status === 0) {
                this.userService.user = null;
                this.router.navigate(['/login'])
              }
            }
          },
          () => {
            
          }
        ));
    }
}


