import { Component, OnInit, Input, SimpleChanges, OnChanges, ElementRef, ViewChild, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { TransactionsMainUIComponent } from 'src/app/core/transactions/transactions-main-ui/transactions-main-ui.component';
import { Transactions } from 'src/app/interfaces/transactions';
import { Dropdown } from 'src/app/interfaces/dropdown';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-transactions-form',
  templateUrl: './transactions-form.component.html',
  styleUrls: ['./transactions-form.component.css']
})
export class TransactionsFormComponent extends TransactionsMainUIComponent implements OnInit, OnChanges {
  @Input() formGroupID: FormGroup;
  @Input() SubmitFunc;
  @Input() formFunc;
  @Input() activeTransaction;
  @Input() subcategories;
  @Input() transactions: Transactions[];
  @Input() accounts;
  @Input() viewAll;
  @Input() activeAccountID;
  @Output() cancelTransactionEdit = new EventEmitter();

  @ViewChild('payee', {static: true}) payee: ElementRef;
  @ViewChildren('payee_dropdown') payeeDropdown: QueryList<ElementRef>;
  @ViewChild('subcategoriesID', {static: true}) subcategoriesID: ElementRef;
  @ViewChildren('subcategories_dropdown') subcategoryDropdown: QueryList<ElementRef>;
  @ViewChild('accountID', {static: true}) accountID: ElementRef;
  @ViewChildren('account_dropdown') accountDropdown: QueryList<ElementRef>;

  activeCount: number = 0;
  dropdownList: Dropdown = new Dropdown;
  showDropdownList: Object = {'subcategories_id': false, 'payee': false, 'accounts': false};

  editing = false;

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.formFunc !== undefined) {
      if(changes.formFunc.currentValue === 'edit') {
        this.renderer.setProperty(this.payee.nativeElement, 'autofocus', false)
        this.renderer.setProperty(this.payee.nativeElement, 'autofocus', true)
        this.formGroupID.controls['date'].setValue(this.activeTransaction.date)
        this.formGroupID.controls['payee'].setValue(this.activeTransaction.payee)
        this.formGroupID.controls['memo'].setValue(this.activeTransaction.memo)
        this.formGroupID.controls['account_id'].setValue(this.activeTransaction.account_id)

        if(this.viewAll) {
          this.renderer.setProperty(this.accountID.nativeElement, 'value', this.getAccountByID(this.activeTransaction.account_id))
        }
          

        if(this.activeTransaction.income_date !== null) {
          this.renderer.setProperty(this.subcategoriesID.nativeElement, 'value', this.getSubcategoryByID(this.activeTransaction.subcategories_id, this.activeTransaction.income_date))
          this.formGroupID.controls['subcategories_id'].setValue(this.activeTransaction.subcategories_id + ';' + this.activeTransaction.income_date)
        } else {
          if(this.activeTransaction.subcategories_id === -2) {
            this.renderer.setProperty(this.subcategoriesID.nativeElement, 'value', 'Starting Balance')
            this.formGroupID.controls['subcategories_id'].setValue(-2)
          } else {
            this.renderer.setProperty(this.subcategoriesID.nativeElement, 'value', this.getSubcategoryByID(this.activeTransaction.subcategories_id))
            this.formGroupID.controls['subcategories_id'].setValue(this.activeTransaction.subcategories_id)
          }
        }

        if(this.activeTransaction.in !== null) {
          this.formGroupID.controls['in'].setValue(this.activeTransaction.in)
          this.formGroupID.controls['out'].setValue("")
        } else {
          this.formGroupID.controls['out'].setValue(this.activeTransaction.out)
          this.formGroupID.controls['in'].setValue("")
        }
      }
    }
  }

  cancelEdit(id) {
    this.cancelTransactionEdit.emit(id)
  }

  isNewForm(formType) {
    if(formType === 'new') {
      return 100;
    } else {
      return 0;
    }
  }

  closeAllLists() {
    this.showDropdownList['subcategories_id'] = false;
    this.showDropdownList['payee'] = false;
    this.showDropdownList['accounts'] = false;
  }

  setPayeeFormValue(value: string, form: FormGroup) {
    return form.controls['payee'].setValue(value);
  }

  setAccountsFormValue(value: string, form: FormGroup) {
    let account_id = this.accounts.filter((account) => account.name === value)

    if(account_id.length === 0) {
      form.controls['account_id'].setValue('')
    } else {
      form.controls['account_id'].setValue(account_id[0].id)
    }
  }

  setSubcategoriesFormValue(value: string, form: FormGroup, formFunc) {
    if(value.split(' ')[0] === 'Income') {
      let month = value.split(' ')[2];
      let year = value.split(' ')[3];
      let months = { 'Janurary': '01', 'February': '02', 'March': '03', 'April': '04', 'May': '05', 'June': '06', 
                     'July': '07', 'August': '08', 'September': '09', 'October': '10', 'November': '11', 'December': '12'}

      return form.controls['subcategories_id'].setValue('-1;'+months[month]+ '-' +year)
    } else {
      if(value === 'Starting Balance' && formFunc === 'edit') {
        return form.controls['subcategories_id'].setValue(-2)
      } else {
        let subcategory = this.subcategories.filter((subcategory) => subcategory.name === value && subcategory.hidden !== true)

        if(subcategory.length === 0) {
          return form.controls['subcategories_id'].setValue('')  
        } else {
          return form.controls['subcategories_id'].setValue(subcategory[0].id)
        }
      }
    }
  }

  checkIfValid(form: FormGroup, type: string) {
    if(type === 'subcategory') {
      return (form.controls['subcategories_id'].status === "VALID") ? false : true;
    } else {
      if(form.controls['account_id'] !== undefined)
        return (form.controls['account_id'].status === "VALID") ? false : true;
    }
  }

  getSubcategoryByID(id, income_date?) {
    if(income_date) {
      let month: number = income_date.split('-')[0]
      let year: number = income_date.split('-')[1]
      const monthsHumanized: string[] = ['Janurary', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      return "Income for " + monthsHumanized[month-1] + " " + year
    } else {
      return this.subcategories.filter((subcategory) => subcategory.id === id)[0].name
    }
  }

  getAccountByID(id) {
    console.log(this.accounts)
    let account = this.accounts.filter((account) => account.id === id)

    if(account.length > 0)
      return account[0].name
  }

  getUniqueTransactionNames() {
    let uniques: [] = []

    if(this.transactions === undefined) 
      return;

    for(let transaction of this.transactions) {
      if(uniques.filter((u: Transactions) => u.payee === transaction.payee).length === 0) {
        //uniques.push(transaction.payee)
      }
    }

    return uniques.reverse();
  }

  getSubcategoryNames(dateValue: string, formFunc) {
    let uniques = []
    let date: string = (dateValue === undefined) ? this.formGroupID.controls['date'].value : dateValue;

    if(this.subcategories === undefined) 
      return;

    for(let subcategory of this.subcategories) {
      if(subcategory.hidden === false) {
        if(uniques.filter((u) => u === subcategory.name).length === 0) {
          uniques.push(subcategory.name)
        }
      }
    }

    for(let i = -1; i < 2; i++) {
      const monthsHumanized: string[] = ['Janurary', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let month: number = +date.split('/')[0]+i
      let year: number = +date.split('/')[2]

      if(month > 12) {
        month = 1;
        year += 1;
      } else if(month === 0) {
        month = 12;
        year -= 1;
      }
      
      uniques.push('Income for ' + monthsHumanized[month-1] + ' ' + year)
    }

    if(formFunc === 'edit')
      uniques.push('Starting Balance')

    return uniques;
  }

  getAccountNames() {
    let uniques: Account[] = []

    if(this.accounts === undefined) 
      return;

    for(let account of this.accounts) {
      if(uniques.filter((u) => u === account.name).length === 0) {
        uniques.push(account.name)
      }
    }

    return uniques;
  }

  generateDropdownList(input: string[], arr: string[]) {
    input = String(input).toLowerCase().split('');
    let percentage: number = 0;
    let componentList: string[] = arr;
    let tempList: string[] = []
    this.dropdownList.items = [];
    
    for(let result of componentList) {
      let nameSplit = String(result).toLowerCase().split('')
      percentage = 0;
      for(let out of input) {
        for(let i = 0; i < nameSplit.length; i++) {
          if(out === nameSplit[i]) {
            percentage = percentage + 1;
            nameSplit.splice(i, 1);
            break;
          }
        }
      }
      if(percentage === input.length) {
        tempList.push(result)
      }
    }
    this.dropdownList.items = tempList;
  }

  keyboardEventHandler(event, form: FormGroup, selectorClass, inputClass: string, inputID: string) {
    if(selectorClass.first === undefined)
      return;

    let dropdownChildren = selectorClass.first.nativeElement.children;

    switch(event.key) {
      case 'ArrowDown':
        if(this.activeCount <= dropdownChildren.length-1) {
          if(this.activeCount === 0) {
            this.renderer.setAttribute(dropdownChildren[0], 'class', 'dropdown-result active')
            this.renderer.setStyle(inputClass, 'caret-color', 'transparent');
          } else {
            this.renderer.setAttribute(dropdownChildren[this.activeCount-1], 'class', 'dropdown-result')
            this.renderer.setAttribute(dropdownChildren[this.activeCount], 'class', 'dropdown-result active')
            this.renderer.setStyle(inputClass, 'caret-color', 'transparent');
            selectorClass.first.nativeElement.scrollTo(0, dropdownChildren[this.activeCount].scrollHeight * this.activeCount)
          }
          this.activeCount++
        }
        break;
      case 'ArrowUp':
        if(this.activeCount > 1) {
          this.activeCount--
          this.renderer.setAttribute(dropdownChildren[this.activeCount], 'class', 'dropdown-result')
          this.renderer.setAttribute(dropdownChildren[this.activeCount-1], 'class', 'dropdown-result active')
          this.renderer.setStyle(inputClass, 'caret-color', 'transparent');
          selectorClass.first.nativeElement.scrollTo(0, (dropdownChildren[this.activeCount].scrollHeight * this.activeCount) - dropdownChildren[this.activeCount].scrollHeight)
        }
        break;
      case 'Enter':
        this.closeAllLists();
        if(this.activeCount > 0) {
          console.log(this.subcategoriesID)
          console.log(inputClass)
          this.renderer.removeStyle(inputClass, 'caret-color');
          this.renderer.setProperty(inputClass, 'value', dropdownChildren[this.activeCount-1].id)
        }
        this.activeCount = 0;
        break;
      case 'Tab':
        this.closeAllLists();
        if(this.activeCount > 0) {
          this.renderer.setProperty(inputClass, 'value', dropdownChildren[this.activeCount-1].id)
          this.renderer.removeStyle(inputClass, 'caret-color');
        }
        this.activeCount = 1;
        break;
      default:
        this.activeCount = 0;
        this.renderer.removeStyle(inputClass, 'caret-color');
        break;
    }
  }

  addTransaction(payload: Transactions, date: string, f: FormGroup, account_id: number) {
    if (f.status === "INVALID")
      return

    if(account_id !== -1) {
      payload.account_id = account_id
    }
    
    let subcategory_check: number | string[] = (typeof(payload.subcategories_id) === 'string') ? payload.subcategories_id.split(';') : payload.subcategories_id;

    if(typeof(subcategory_check) === 'object') {
      payload.income_date = subcategory_check[1]
      payload.subcategories_id = -1;
    }

    payload.date = date

    return this.transactionsService.addTransaction(payload).subscribe(
    (transaction: Transactions) => {
      f.controls['payee'].setValue('');
      f.controls['memo'].reset();
      f.controls['in'].setValue('');
      f.controls['out'].setValue('');
      
      this.transactions.push(transaction)
      this.transactionsService.updateTransactionsObject('multi', this.transactions);
      this.payee.nativeElement.focus();
      this.activeCount = 0;
    });
  }

  updateTransaction(payload: Transactions, date: string, f: FormGroup, account_id: number, id: number) {
    if (f.status === "INVALID")
    return
    
    let subcategory_check: number | string[] = (typeof(payload.subcategories_id) === 'string') ? payload.subcategories_id.split(';') : payload.subcategories_id;

    if(typeof(subcategory_check) === 'object') {
      payload.income_date = subcategory_check[1]
      payload.subcategories_id = -1;
    }

    payload.date = date

    return this.transactionsService.updateTransaction(id, payload).subscribe(
    (transaction: Transactions) => {
      f.controls['payee'].setValue('');
      f.controls['memo'].reset();
      f.controls['in'].setValue('');
      f.controls['out'].setValue('');
      
      let oldID = this.transactions.findIndex((transaction) => transaction.id === id)
      this.transactions[oldID] = transaction
      this.transactionsService.updateTransactionsObject('multi', this.transactions);
      this.cancelTransactionEdit.emit(id)
      this.activeCount = 0;
    });
  }

}
