import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/users/user.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  nickname: string;
  email: string;
  password: string;
  password_confirmation: string;

  constructor(private title: Title, public userService: UserService, private router: Router) { }

  register(u, cu, p, cp) {
    this.userService.register(u, cu, p, cp).subscribe(
      () => {
        this.router.navigate(['/budget']);
        this.userService.setErrors(undefined)
      },
      err => { 
        this.userService.setErrors(err.error.errors.full_messages)
      }
    );
  }

  ngOnInit() {
    this.title.setTitle('MrBudget –– Register')
    localStorage.removeItem('access-token');
    localStorage.removeItem('uid');
    localStorage.removeItem('client');
  }

}
