import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable()
export class AuthService {
  constructor(private userService: UserService) {}

  public validate_token() {
    let is_valid: boolean = localStorage.getItem('access-token') != null && localStorage.getItem('uid') != null && localStorage.getItem('client') != null;
    
    if(is_valid) {
      this.userService.validate();
      this.userService.getUser().subscribe(
        (_user: any) => {
          this.userService.user = _user.body.data;
        })
    }

    return is_valid;
  }
}
