import { Component, OnInit, Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subcategories } from '../../../interfaces/subcategories';
import { Categories } from '../../../interfaces/categories';
import { Budgets } from '../../../interfaces/budgets';
import { Month } from '../../../interfaces/month';
import { BudgetComponent } from '../budget.component';
import { User } from 'src/app/interfaces/user';
import { BudgetData } from 'src/app/interfaces/budgetData';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-budget-main-ui',
  templateUrl: './budget-main-ui.component.html',
  styleUrls: ['./budget-main-ui.component.css']
})

export class BudgetMainUIComponent extends BudgetComponent implements OnInit {
  
  // use an array to cut the below down?
  updateCategoryForm;
  addSubcategoryForm;
  updateSubcategoryForm;
  editDefaultNameForm;
  addCategoryForm;
  @ViewChild('hiddenInput', {static: false}) hiddenInput: ElementRef;
  @ViewChild('editDefaultName', {static: false}) editDefaultName: ElementRef;
  @ViewChild('categoryEdit', {static: false}) categoryEdit: ElementRef;
  @ViewChild('categoryAdd', {static: false}) categoryAdd: ElementRef;
  @ViewChild('subcategoryEdit', {static: false}) subcategoryEdit: ElementRef;
  // try to eliminate any of the below
  @Input() user: User;
  @Input() default_budget: Budgets;
  active = [0, 1, 2];
  budget: Budgets[];
  months: Month[] = [];
  currentMonth: String = '';
  categories: Categories[] = [];
  subcategories: Subcategories[] = [];
  editing = false;
  catIsNegative: boolean[];

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.setBudgetTableLimit(event);
  }

  checkNegative(value, id) {
    console.log(value)
    if(this.catIsNegative[id] === undefined)
      this.catIsNegative[id] = false;

    if(value < 0) {
      return this.catIsNegative[id] = true
    }
  }

  selectInputText() {
    setTimeout(() => {
      this.editDefaultName.nativeElement.focus();
      this.editDefaultName.nativeElement.setSelectionRange(0, 100);
    }, 50);
  }

  setCategoryAddFocus() {
    setTimeout(() => {
      this.categoryAdd.nativeElement.focus();
    })
  }

  setCategoryEditFocus(val) {
    setTimeout(() => {
      this.categoryEdit.nativeElement.focus();
      this.categoryEdit.nativeElement.value = val
      this.categoryEdit.nativeElement.setSelectionRange(0, 100);
    }, 50);
  }

  setSubcategoryEditFocus(val) {
    setTimeout(() => { 
      this.subcategoryEdit.nativeElement.focus();
      this.subcategoryEdit.nativeElement.value = val
      this.subcategoryEdit.nativeElement.setSelectionRange(0, 100);
    }, 50);
  }

  setDefault() {
    if(this.default_budget.id !== this.user.default_budget_id) {
      this.user.default_budget_id = this.default_budget.id
      return this.budgetService.setDefault(this.default_budget.id, this.user.id).subscribe((_default: Budgets) => console.log(_default));
    }
  }

  arr2str(array) {
    let final = '';

    for(let i = 0; i < array.length; i++) {
      final = (final === '') ? ';' + array[i] : final + ';' + array[i]; 
    }

    return final;
  }

  str2arr(string) {
    let arr = (string !== null) ? string.split(';') : []
    
    if(arr[0] === '') {
      arr.shift();
    }

    return arr
  }

  /**
   * CSS/JS trick to get content-wrapper to blanket the client height, UX design choice.
   * Only used in main-budget.component.html via [ngStyle]
   */
  getClientHeight() {
    return window.innerHeight;
  }

  getClientWidth() {
    return window.innerWidth;
  }

  isDesktop () {
    return this.getClientWidth() > 1280 ;
  }

  updateBudgetName(budget, data) {
    if (this.editDefaultNameForm.status === "INVALID") 
      return;

    if (budget.name === data.budget.name)
      return;

    this.budgetService.updateBudget(budget.id, data).subscribe(
      (_data) => {
        this.editing = false;
        budget.name = data.budget.name
      })
  }

  /**
   * initial load of categories and subcategories; 
   * may be reduced when adding organizing 
   */
  setCategories() {
    this.budgetService.categoriesObs.pipe(take(2)).subscribe(
      (data: Categories[]) => {
        this.categories = data
        this.budgetService.subcategoriesObs.pipe(take(2)).subscribe(
          (d: Subcategories[]) => {
            this.subcategories = d;
            for (let i = 0; i < this.categories.length; i++) {
              let sids = []
              this.categories[i].show = true;
              this.categories[i].subcategories = [];
              sids = this.str2arr(this.categories[i].subcategory_sort_order); 
              for (let j = 0; j < sids.length; j++) {
                for (let k = 0; k < this.subcategories.length; k++) {
                  const subcategories: Subcategories = this.subcategories[k];
                  if (subcategories.categories_id === this.categories[i].id && this.subcategories[k].id === Number(sids[j]).valueOf()) {
                    this.categories[i].subcategories.push(subcategories);
                    break;
                  }
                }
              }
            }
          }
        );
      }
    );
  }

  updateSubcategories(category, subcategory) {
    let sids = this.str2arr(category.subcategory_sort_order) 

    category.subcategories = []
    for(let i = 0; i < sids.length; i++) {
      for (let k = 0; k < subcategory.length; k++) {
        if((subcategory[k].id === Number(sids[i]).valueOf())) { 
          category.subcategories.push(subcategory[k]);
          break;
        }
      }
    }
  }

  /* SUBCATEGORIES */
  addSubcategory(payload, form, category) {
    if (this.addSubcategoryForm.status === "INVALID") 
      return;

    this.budgetService.addSubcategory(payload).subscribe(
      (data: Subcategories) => {
        this.updateSortOrder('add', category, data)
        this.subcategories.push(data);
        this.updateSubcategories(category, this.subcategories)
        form.reset();
      }
    );
  }

  hideSubcategory(category, subcategory) {
    let sids: any[] = this.str2arr(category.subcategory_sort_order)

    for(let i = 0; i < sids.length; i++) {
      if(Number(sids[i]).valueOf() === subcategory.id) {
        const result = sids.filter(res => Number(res).valueOf() !== subcategory.id);
        category.subcategory_sort_order = this.arr2str(result)
        this.budgetService.updateSubcategory(subcategory.id, {subcategory: {hidden: true}}).subscribe((_data) => {console.log(_data)});
        this.budgetService.updateCategory(category.id, {category: {subcategory_sort_order: category.subcategory_sort_order}}).subscribe((_data) => {});
        subcategory.hidden = true;
        this.updateSubcategories(category, this.subcategories)
        break;
      }
    }
  }

  updateSubcategory(subcategory, payload) {
    if (this.updateSubcategoryForm.status === "INVALID") 
      return

    this.budgetService.updateSubcategory(subcategory.id, payload).pipe(take(1)).subscribe(
      () =>  { 
        subcategory.name = payload.subcategory.name;
        subcategory.edit = false;
        this.updateSubcategoryForm.patchValue({'editSubcategoryName': null});
      }
    );
  }

  /**
   * CATEGORIES
   */

  addCategory(payload, form) {
    if (this.addCategoryForm.status === "INVALID") 
      return

    this.budgetService.addCategory(payload).subscribe(
      (data: Categories) => {
        this.categories.push(data);
        this.setCategories();
        form.reset();
      }
    );
  }

  updateCategory(category, payload) {
    if (this.updateCategoryForm.status === "INVALID")
      return
  
    this.budgetService.updateCategory(category.id, payload).subscribe(
      () => {
        category.name = payload.category.name;
        category.edit = false;
        this.updateCategoryForm.patchValue({'editCategoryName': null});
      }
    );
  }

  deleteCategory(id) {
    
    //TODO: add ability to detect if category has subcategories and deny request to delete.
    
    // this.budgetService.deleteCategory(id).subscribe(
    //  () => {
    //    this.setCategories();
    //  }
    // );
  }

  /**
   * Subcategory Sort Functon, allows user to move individual subcategories up or down within the category
   */

  updateSortOrder(type, category, subcategory) {
    let current: String = category.subcategory_sort_order || null
    current = (current === null) ? ';' + subcategory.id : current +  ';' + subcategory.id
    
    this.budgetService.updateCategory(category.id, {category: {subcategory_sort_order: current}}).pipe(take(1)).subscribe((_data) => {});

    category.subcategory_sort_order = current;

    return current;
  }


  // TODO: cut this in half, only diffs are denoted by *
  changeOrder(toggle, category, subcategory) {
    let order = this.str2arr(category.subcategory_sort_order);
    let index, current;
    switch (toggle) {
      case 'up':
        for(let i = 0; i < order.length; i++) {
          if(Number(order[i]).valueOf() === subcategory.id) {
            index = i
          }
        }

        current = order[index]
        let prevID = order[index-1] // *

        order[index-1] = current // *
        order[index] = prevID

        let result = this.arr2str(order)
        
        category.subcategory_sort_order = result;
        //console.log(result)

        this.budgetService.updateCategory(category.id, {category: {subcategory_sort_order: result}}).pipe(take(1)).subscribe();
        this.updateSubcategories(category, this.subcategories)
        break;
      case 'down':
        for(let _i = 0; _i < order.length; _i++) {
          if(Number(order[_i]).valueOf() === subcategory.id) {
            index = _i
          }
        }

        current = order[index]
        let nextID = order[index+1] // *
        order[index+1] = current // *
        order[index] = nextID

        let _result = this.arr2str(order)

        //console.log(_result)
        category.subcategory_sort_order = _result;

        this.budgetService.updateCategory(category.id, {category: {subcategory_sort_order: _result}}).subscribe();
        this.updateSubcategories(category, this.subcategories)
        break;
      default:
        console.log('error');
    }
  }

  setBudgetTableLimit(width) {
    width = this.getClientWidth();
    let limit = 0;

    if (width >= 1600) {
      limit = 3;
      this.active = [0, 1, 2];
    } else if (width < 1600 && width >= 1300) {
      limit = 2;
      this.active = [0, 1];
    } else if (width < 1300) {
      limit = 1;
      this.active = [0];
    };

  }

  getActiveMonthsOnView() {
    const activeMonthsOnView = localStorage.getItem('ActiveMonthsOnView');
    if (activeMonthsOnView) {
      const months: any[] = [];
      const date = activeMonthsOnView.split(',');

      for (let i = 0; i < date.length; i++) {
        months[i] = { month: date[i].split('-')[0], year: date[i].split('-')[1]};
      }

      return months;
    }
  }

  adjustTotal(index, category) {
    let final = 0;
    let arrLen;

    if(this.months[index] !== undefined)
      arrLen = this.months[index].data.filter((_data) => (category.id === _data.categories_id))
      
    if(arrLen !== undefined && this.subcategories.length > 0) {
      for (let i = 0; i < arrLen.length; i++) {  
        for (let j = 0; j < this.subcategories.length; j++) {
          if (this.subcategories[j].id === arrLen[i].subcategories_id) {
            if(this.subcategories[j].hidden === true && this.subcategories[j].categories_id === category.id) {
              final = (this.months[index].data[this.subcategories[j].id].amount === undefined) ? final : final + this.months[index].data[this.subcategories[j].id].amount;
              break;
            }
          }
        }
      }
    }
    
    return (final === null) ? 0 : final;
  }

  generateMonths(initial, browser_date?, toggle?) {
    const dateTempArr: any[] = [];
    const getActiveMonthsOnView =
      (localStorage.getItem('ActiveMonthsOnView') !== null && localStorage.getItem('ActiveMonthsOnView') !== '');
    const monthsHumanized = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    /**
     * const dates;
     * 1: Checks if localstorage has been set and this is the initial run of generateMonths,
     *    then it generates months based on months accessed on previous visit.
     * 2: Checks if initial is true and generates months  based on current month + 2 months in the future.
     * 3: finally if inital or
     */
    const dates = (initial && getActiveMonthsOnView) ? this.getActiveMonthsOnView() : (initial) ? this.budgetService.generateBudgetDates()
    : this.budgetService.modifyBudgetDates(toggle, browser_date, this.months);
    let takeCount = 2;

    /**
     * 
     * to only load single month and push/pull existing months into different parts of the array. 
     * Use the toggle parameter to see which direction the months will go.
     * if last:
     *  push index 0,1 to 1,2 and load in new 0 array.
     * if next:
     *  push index 1,2 to 0,1 and load in new 2 array.
     * 
     */

    if(this.months !== [] && toggle) {
      //console.log('months are active')
      takeCount = 1;
    }

    for (let i = 0; i < dates.length; i++) {
      let month: string;
      if (dates[i].month.length === 1 && dates[i].month !== 0) {
        month = '0' + dates[i].month;
      } else {
        month = dates[i].month;
      }

      const date = month + '-' + dates[i].year;

      this.months[i] = new Month();
      this.months[i].date = date;
      this.months[i].humanizedDate = monthsHumanized[Number(date.split('-')[0]) - 1] + ' ' + date.split('-')[1];
      this.months[i].loading = true;

      if (!initial) {
        dateTempArr.push(date);
      }

      const temp = new Date();
      this.currentMonth = temp.getMonth() + 1 + '-' + temp.getFullYear();

      this.budgetService.filterByDate(date).pipe(take(takeCount)).subscribe(
        (budget_data: BudgetData[]) => {          
          budget_data.forEach(el => {
            if (this.months[i].data[el.subcategories_id] !== null) {
              this.months[i].data[el.subcategories_id] = el;

              this.months[i].totals[el.categories_id] =
                (this.months[i].totals[el.categories_id] === undefined) ? 0 : this.months[i].totals[el.categories_id];

              /* last conditional on the if statement below will avoid duplicating the total. check for alternative?  */
              if ((this.months[i].data[el.subcategories_id].categories_id === el.categories_id) && (this.months[i].date === date) && (this.months[i].totals[el.categories_id] !== el.amount)) {
                  this.months[i].totals[el.categories_id] =
                    +this.months[i].totals[el.categories_id] + +el.amount;
              }

            }
          });
        },
        null,
        () => {
          this.months[i].loading = false;
        }
      );
    }

    if (!initial) {
      localStorage.setItem('ActiveMonthsOnView', dateTempArr[0] + ',' + dateTempArr[1] + ',' + dateTempArr[2]);
    }

  }

  resetBudgetMonths() {
    localStorage.removeItem('ActiveMonthsOnView');
    this.generateMonths(true);
  }

  /**
   * used in main-budget.component.html, line 70.
   * @param date current month and the year in the format MM-YYYY (E.G: 11-2019)
   */
  public checkCurrentMonth(date) {
    if (date !== null) {
      if ((Number(date.split('-')[0]).valueOf() < Number(this.currentMonth.split('-')[0]).valueOf())
        && ((Number(date.split('-')[1]).valueOf() === Number(this.currentMonth.split('-')[1]).valueOf())))  {
          return ['#FFF', '#888'];
      } else if ((Number(date.split('-')[0]).valueOf() <= 12)
        && (Number(date.split('-')[1]).valueOf() < Number(this.currentMonth.split('-')[1]).valueOf())) {
          return ['#FFF', '#888'];
      }
    }
    return ['#000', '#333'];
  }

  loadBudget(toggle, date) {
    switch (toggle) {
      case 'last':
        this.generateMonths(false, date, 'last');
        break;
      case 'next':
        this.generateMonths(false, date, 'next');
        break;
      default:
        return;
    }
  }

  ngOnInit() {
    /** to prevent multiple arrays being loaded on initial view, Add any functions to the child compoenets of which are extending this component */
    this.editDefaultNameForm = new FormGroup({
      editDefaultName: new FormControl("", Validators.compose([
        Validators.required,
        Validators.nullValidator,
        Validators.minLength(1),
      ]))
    });
  }

}
