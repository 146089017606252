import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { AccountsComponent } from '../accounts.component';
import { switchMap, share } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { Categories } from 'src/app/interfaces/categories';
import { Subcategories } from 'src/app/interfaces/subcategories';
import { Transactions } from 'src/app/interfaces/transactions';
import { Observable } from 'rxjs/internal/Observable';
import { CheckInOutValues } from '../../transactions/transactions-main-ui/transaction-validator.validator';

@Component({
  selector: 'app-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.css'],
  providers: [CurrencyPipe]
})
export class AccountViewComponent extends AccountsComponent implements OnInit {
  account: Observable<Object>;
  categories: Categories[];
  subcategories: Subcategories[];
  transactionsObs: Observable<Transactions[]>;

  ngOnInit() {
    this.budgetService.getCategories().subscribe((_categories: Categories[]) => this.categories = _categories)
    this.budgetService.getSubcategories().subscribe((_subcategories: Subcategories[]) => this.subcategories = _subcategories)

    this.transactionsService.transactionsObs.subscribe((trx) => {
      this.transactions = [];
      this.transactions.push(...trx)
    });

    this.transactionsService.updateTransactionsObject('init');
    this.accountsService.updateAccountsObject('init');

    this.account = this.route.paramMap.pipe(
      switchMap(params => {
        let id = +params.get('id');
        this.activeAccountID = id;
        return this.accountsService.getAccount(id)})
    ).pipe(share());

    this.newTransactionForm = this.formbuilder.group({
      date: new FormControl(this.currentDate.toString(), Validators.required),
      payee: new FormControl('', Validators.required),
      subcategories_id: new FormControl('', Validators.required),
      memo: new FormControl(''),
      in: new FormControl(''), 
      out: new FormControl('')
    },
    {
      validator: CheckInOutValues("in", "out")
    });

    this.title.setTitle('MrBudget –– Account')
  }

  updateAccount(user_id, id, payload) {
    return this.accountsService.updateAccount(user_id, id, payload);
  }

}
