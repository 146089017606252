import { Component, OnInit } from '@angular/core';
import { AccountsComponent } from '../accounts.component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-accounts-new',
  templateUrl: './accounts-new.component.html',
  styleUrls: ['./accounts-new.component.css'],
  providers: [CurrencyPipe]
})
export class AccountsNewComponent extends AccountsComponent implements OnInit {
  
}
