import { Injectable, OnInit } from '@angular/core';
import { UserService } from '../users/user.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class BudgetDataService implements OnInit {

  private base_url = 'https://api.mrbudget.io/api/v1/budget_data/';
  private tokens: string[] = ['access-token', 'client', 'uid'];
  private headers: Headers = new Headers({'content-type': 'application/json', 'accept': 'application/json'});
  
  constructor(private userService: UserService, private http: HttpClient) { }

  ngOnInit(): void {}

}
