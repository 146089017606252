import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Dropdown } from 'src/app/interfaces/dropdown';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.css']
})
export class AutocompleteInputComponent implements OnInit {
  @Input() inputID;
  @Input() formID;
  @Input() componentArray;
  @Input() dropdownArray;
  @Input() placeholder;
  
  val = '';
  activeCount: number = 1;
  showList: Object = {'subcategories_id': false, 'payee': false, 'account_id': false};
  dropdownList: Dropdown = new Dropdown;

  constructor() {}

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  closeAllLists() {
    this.showList['subcategories_id'] = false;
    this.showList['payee'] = false;
    this.showList['account_id'] = false;
  }

  setFormValue(component, formValue, form) {
    switch(component) {
      case 'subcategories_id': //newTransactionForm
        //console.log(formValue)
        if(this.componentArray.filter((result) => result.name.toLowerCase() === formValue.toLowerCase())[0] === undefined) {
          return form.controls['subcategories_id'].setValue('');
        } else { 
          return form.controls['subcategories_id'].setValue(this.componentArray.filter((result) => result.name.toLowerCase() === formValue.toLowerCase())[0].id); 
        }
      case 'payee': //newTransactionForm
        return form.controls['payee'].setValue(formValue);
      case 'account_id': //newTransactionForm
        //console.log(formValue)
        if(this.componentArray.filter((result) => result.name.toLowerCase() === formValue.toLowerCase())[0] === undefined) {
          return form.controls['account_id'].setValue('');
        } else { 
          return form.controls['account_id'].setValue(this.componentArray.filter((result) => result.name.toLowerCase() === formValue.toLowerCase())[0].id); 
        }
    }
  }

  /** 
   * GENERIC FUNCTION; DO NOT COUPLE TO ANY MAJOR COMPONENTS
   * Multi component drop down list generator
   * @params input - user input obtained from the form (on key down)
   *         componentArray - Array of results which we are going to filter through. 
   * 
   * Can only use a single array of items, no objects. ['1', '2', '3']
   * 
   */
  generateDropdownList(input, arr) {
    input = String(input).toLowerCase().split('');
    let percentage: number = 0;
    let componentList = arr;
    let tempList = []
    this.dropdownList.items = [];
    
    for(let result of componentList) {
      //console.log(componentList)
      let nameSplit = String(result).toLowerCase().split('')
      percentage = 0;
      for(let out of input) {
        for(let i = 0; i < nameSplit.length; i++) {
          if(out === nameSplit[i]) {
            percentage = percentage + 1;
            nameSplit.splice(i, 1);
            break;
          }
        }
      }
      if(percentage === input.length) {
        tempList.push(result)
      }
    }
    this.dropdownList.items = tempList;
  }

  keyboardEventHandler(event, form, selectorClass, inputID) {
    let dropdownChildren;

    switch(event.key) {
      case 'ArrowDown':      
        if(document.querySelector(selectorClass) !== null) {
          dropdownChildren = document.querySelector(selectorClass).children;
          if(this.activeCount <= dropdownChildren.length-1) {
            if(this.activeCount === 0) {
              this.activeCount++
              dropdownChildren[0].className = dropdownChildren[0].className + ' active';
            } else {
              dropdownChildren[this.activeCount-1].className = dropdownChildren[this.activeCount-1].className.split(' ')[0];
              dropdownChildren[this.activeCount].className = dropdownChildren[this.activeCount].className + ' active';
              this.activeCount++
            }
          }
        }
        break;
      case 'ArrowUp':
        if(this.activeCount > 1) {
          dropdownChildren = document.querySelector(selectorClass).children;
          this.activeCount--
          dropdownChildren[this.activeCount].className = dropdownChildren[this.activeCount].className.split(' ')[0];
          dropdownChildren[this.activeCount-1].className = dropdownChildren[this.activeCount-1].className + ' active';
        }
        break;
      case 'Enter':
        this.closeAllLists();
        dropdownChildren = document.querySelector(selectorClass).children;
        let resultName = dropdownChildren[this.activeCount-1];
        
        //console.log(resultName)
        if(resultName) {
          form.controls[inputID].value = resultName.id
          form.controls[inputID].value
          this.val = resultName.id
        }
        console.log(form)
        break;
      case 'Tab':
        this.closeAllLists();
        dropdownChildren = document.querySelector(selectorClass).children;
        let _resultName = dropdownChildren[this.activeCount-1];
        
        if(_resultName) {
          form.controls[inputID].value = _resultName.id
          this.val = _resultName.id
        }
        //console.log(form)
        break;
      default:
        this.activeCount = 0;
        setTimeout((i) => {
          if(dropdownChildren !== undefined)
            return;

          dropdownChildren = document.querySelector(selectorClass).children;
          for(let dd of dropdownChildren) {
            dd.className = dd.className.split(' ')[0];
          }
          this.activeCount = 1;
          if(dropdownChildren !== undefined) {
            dropdownChildren[this.activeCount-1].className = dropdownChildren[this.activeCount-1].className + ' active';
          }  
        }, 100)
        break;
    }
  }

}
