import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../services/users/user.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() email;
  @Input() password;

  constructor(private title: Title, public userService: UserService, private router: Router) {}

  login(u, p) {
    this.userService.login(u, p).subscribe(
      (_user: any) => {
        this.userService.setUser(_user)
        this.userService.setErrors(undefined)
        localStorage.setItem('access-token', _user.headers.get('access-token'));
        localStorage.setItem('uid', _user.headers.get('uid'));
        localStorage.setItem('client', _user.headers.get('client'));
        this.router.navigate(['budget']);
      },
      err => {
        this.userService.setErrors(err.error.errors)
      }
    );
  }

  ngOnInit() {
    this.title.setTitle('MrBudget –– login')
    localStorage.removeItem('access-token');
    localStorage.removeItem('uid');
    localStorage.removeItem('client');
  }

}
