import { Component, OnInit, SimpleChanges, OnChanges, Input } from '@angular/core';
import { Transactions } from 'src/app/interfaces/transactions';
import { BudgetData } from 'src/app/interfaces/budgetData';

@Component({
  selector: 'app-budget-monthly-cashflows',
  templateUrl: './budget-monthly-cashflows.component.html',
  styleUrls: ['./budget-monthly-cashflows.component.css']
})
export class BudgetMonthlyCashflowsComponent implements OnInit, OnChanges {

  @Input() date: string;
  @Input() transactions: Transactions[];
  @Input() budget_data: BudgetData[];

  inflow: number[] = [];
  outflow: number[] = [];
  overflow: number[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.transactions !== undefined) {
      if(changes.transactions.currentValue !== changes.transactions.previousValue) {
        this.resetFlows();
        this.compileCashflow(this.date)
        //this.compileInflow(this.date);
      }   
    }
    if(changes.date !== undefined) {
      if(changes.date.currentValue !== changes.date.previousValue) {
        this.resetFlows();
        this.compileCashflow(this.date)
        //this.compileInflow(this.date);
      }
    }
  }

  ngOnInit() {
  }

  resetFlows() {
    this.inflow = []
    this.outflow = []
    this.overflow = []
  }  

  formatDate(date) {
    let splitDate = date.split('/')
    let formattedDate = (splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]) + '-' + splitDate[2];
   
    return formattedDate;
  }

  filterTransactionsByDate(date) {
    return this.transactions.filter((transaction) => this.formatDate(transaction.date) === date)
  }

  filterTransactionsByIncomeDate(date) {
    return this.transactions.filter((transaction) => transaction.income_date === date)
  }

  filterBudgetDataByDate(date) {
    return this.budget_data.filter((budget_data) => budget_data.date === date)
  }

  compileCashflow(date: string) {
    if(this.inflow[date] === undefined)
      this.inflow[date] = 0

    if(this.outflow[date] === undefined)
      this.outflow[date] = 0

    if(this.overflow[date] === undefined)
      this.overflow[date] = 0

    this.filterTransactionsByDate(date).map((t) => {
      if(t.in !== null) {
        if(t.income_date === null && t.income_date !== date)
          return this.inflow[date] += t.in
      } else {
        return this.outflow[date] += t.out
      }
    })

    this.filterTransactionsByIncomeDate(date).map((t) => {
      if(t.income_date === date) {
        return this.inflow[date] += t.in;
      }
    })
    
    if(this.inflow[date] > 0 && this.outflow[date]) {
      this.overflow[date] = ((this.inflow[date] - this.outflow[date]) / this.inflow[date]);
    }

  }

}
