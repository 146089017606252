import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AppComponent } from './app.component';

// Auth Guard
import { AuthService } from './services/users/auth.service'
import { AuthGuardService as AuthGuard, AuthGuardService } from './services/users/auth-guard.service';

// Shared Components
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { ControlSidebarComponent } from './shared/control-sidebar/control-sidebar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { UserMessagesComponent } from './shared/users/user-messages/user-messages.component';
import { UserNotificationsComponent } from './shared/users/user-notifications/user-notifications.component';
import { UserTasksComponent } from './shared/users/user-tasks/user-tasks.component';

// Core Components
import { HomeComponent } from './core/home/home.component';
import { BudgetComponent } from './core/budget/budget.component';
import { LoginComponent } from './core/login/login.component';
import { RegisterComponent } from './core/register/register.component';

// Services
import { UserService } from './services/users/user.service';
import { BudgetService, ReqInterceptor } from './services/budgets/budget.service';
import { AccountService } from './services/accounts/account.service';
import { BudgetDataService } from './services/budget-data/budget-data.service';
import { TransactionsService } from './services/transactions/transactions.service';
// HTTP Error pages
import { PageNotFoundComponent } from './shared/http/page-not-found/page-not-found.component';
import { BudgetNewComponent } from './core/budget/budget-main-ui/budget-new/budget-new.component';
import { BudgetMainUIComponent } from './core/budget/budget-main-ui/budget-main-ui.component';
import { BudgetDatumInputComponent } from './core/budget/budget-main-ui/budget-datum-input/budget-datum-input.component';
import { BudgetViewDesktopComponent } from './core/budget/budget-main-ui/budget-view-desktop/budget-view-desktop.component';
import { BudgetViewMobileComponent } from './core/budget/budget-main-ui/budget-view-mobile/budget-view-mobile.component';
import { AccountsComponent } from './core/accounts/accounts.component';
import { AccountsSidebarComponent } from './core/accounts/accounts-sidebar/accounts-sidebar.component';
import { AccountsNewComponent } from './core/accounts/accounts-new/accounts-new.component';
import { TransactionsMainUIComponent } from './core/transactions/transactions-main-ui/transactions-main-ui.component';
import { TransactionsComponent } from './core/transactions/transactions.component';
import { AccountViewComponent } from './core/accounts/account-view/account-view.component';
import { AccountsFormComponent } from './core/accounts/accounts-form/accounts-form.component';
import { AutocompleteInputComponent } from './shared/autocomplete-input/autocomplete-input.component';
import { TransactionsFormComponent } from './shared/transactions-form/transactions-form.component';
import { TransactionsTableComponent } from './shared/transactions-table/transactions-table.component';
import { BudgetTransactionTotalsComponent } from './core/budget/budget-main-ui/budget-transaction-totals/budget-transaction-totals.component';
import { BudgetBalanceTotalsComponent } from './core/budget/budget-main-ui/budget-balance-totals/budget-balance-totals.component';
import { BudgetMonthlyCashflowsComponent } from './core/budget/budget-main-ui/budget-monthly-cashflows/budget-monthly-cashflows.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'budget', component: BudgetComponent, canActivate: [AuthGuard] },
  { path: 'budgets/new', component: BudgetNewComponent, canActivate: [AuthGuard] },
  { path: 'account/new', component: AccountsNewComponent, canActivate: [AuthGuard] },
  { path: 'account/:id', component: AccountViewComponent, canActivate: [AuthGuard] },
  { path: 'transactions', component: TransactionsMainUIComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BudgetComponent,
    SidebarComponent,
    UserMessagesComponent,
    UserNotificationsComponent,
    UserTasksComponent,
    ControlSidebarComponent,
    RegisterComponent,
    PageNotFoundComponent,
    TransactionsMainUIComponent,
    AccountsSidebarComponent,
    AccountsComponent,
    AccountsNewComponent,
    AccountViewComponent,
    AccountsFormComponent,
    AutocompleteInputComponent,
    TransactionsComponent,
    TransactionsFormComponent,
    TransactionsTableComponent,
    BudgetNewComponent,
    BudgetMainUIComponent,
    BudgetDatumInputComponent,
    BudgetTransactionTotalsComponent,
    BudgetBalanceTotalsComponent,
    BudgetViewDesktopComponent,
    BudgetViewMobileComponent,
    BudgetMonthlyCashflowsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      routes, 
      { enableTracing: false,
        preloadingStrategy: PreloadAllModules }
    )
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: ReqInterceptor, multi: true}, UserService, BudgetService, AccountService, AuthService, AuthGuardService, BudgetDataService, TransactionsService],
  bootstrap: [AppComponent]
  
})
export class AppModule {}
