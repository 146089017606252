import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

export class Guest {
  complete: boolean = false;
  email: string = '';
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  
  guest = new Guest();

  constructor(private title: Title,  private router: Router) { }

  addToMailingList() {
    this.guest['complete'] = true;
    console.log(this.guest);
  }

  getClientHeight() {
    return window.innerHeight;
  }

  ngOnInit() {
    this.title.setTitle('MrBudget –– Build, Locate, Save!')
  }

}
