import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { share, take, map} from 'rxjs/operators';
import { Transactions } from 'src/app/interfaces/transactions';
import { Observable, from, of, BehaviorSubject } from 'rxjs';

@Injectable()
export class TransactionsService {
  private base_url: String = "https://api.mrbudget.io/api/v1/";
  private headers: HttpHeaders = new HttpHeaders({ 'content-type': 'application/json', 'accept': 'application/json' }); 

  public transactionsSource: BehaviorSubject<Transactions[]> = new BehaviorSubject<Transactions[]>([]);
  public transactionsObs: Observable<Transactions[]> = this.transactionsSource.asObservable();

  public totalsObs: Observable<{}>;

  public transactions: Transactions[] = [];
  public flagCheck: boolean = false;

  constructor(private http: HttpClient) {}

  updateTransactionsObject(key, val?) {
    switch(key) {
      case('init'): 
        this.getTransactions().pipe(
            take(1),
            map((transactions: Transactions[]) => {
              let total: {} = {accounts: []};
              transactions.map(t => {
                this.transactionsSource.next(transactions);
                this.transactions = [];
                this.transactions.push(...transactions);

                if(total['accounts'][t.account_id] === undefined) {
                  total['accounts'][t.account_id] = []
                }

                t.in !== null ? total['accounts'][t.account_id] = +total['accounts'][t.account_id] + t.in : total['accounts'][t.account_id] = +total['accounts'][t.account_id] - t.out; // account totals
              })

              this.totalsObs = from([total])
            })
          ).subscribe();
      break;
      case('local'):
        this.transactionsObs.pipe(take(1)).subscribe((transactions) => {
          this.transactions = transactions;
        });
        break;
      case('empty'):
        this.transactions = [];
        this.transactionsSource.next(this.transactions);
        //console.log('empty this.transactions - transactions')
      break;
      case('one'):
        this.transactions.push(val)
        this.transactionsSource.next(this.transactions);

        let total: {} = {accounts: []};
        this.transactions.map(t => {
          if(total['accounts'][t.account_id] === undefined) {
            total['accounts'][t.account_id] = []
          }

          t.in !== null ? total['accounts'][t.account_id] = +total['accounts'][t.account_id] + t.in : total['accounts'][t.account_id] = +total['accounts'][t.account_id] - t.out; // account totals
          
        })
        this.totalsObs = from([total])
      break;
      case('multi'):
        this.transactions = [];
        this.transactions.push(...val)
        this.transactionsSource.next(this.transactions);

        let totals: {} = {accounts: []};
        this.transactions.map(t => {
          
          if(totals['accounts'][t.account_id] === undefined) {
            totals['accounts'][t.account_id] = []
          }

          t.in !== null ? totals['accounts'][t.account_id] = +totals['accounts'][t.account_id] + t.in : totals['accounts'][t.account_id] = +totals['accounts'][t.account_id] - t.out; // account totals
        })
        
        this.totalsObs = from([totals])
        //console.log('multi push to this.transactions - transactions')
      break;
    }
    this.transactionsSource.next(this.transactions)
    return this.transactions;
  }

  getTransactions(): Observable<Transactions[]> {
    let url = this.base_url + 'transactions/'

    return this.http.get<Transactions[]>(url, { headers: this.headers }).pipe(share())
  }

  addTransaction(payload) {
    let url = this.base_url + 'transactions/'
    
    console.log(payload)

    return this.http.post(url, {transaction: {...payload}}, { headers: this.headers })
  }

  updateTransaction(id, payload) {
    let url = this.base_url + 'transactions/' + id
    
    return this.http.put(url, {transaction: {...payload}}, { headers: this.headers })
  }

  deleteTransactions(id) {
    let url = this.base_url + 'transactions/' + id

    return this.http.delete(url, { headers: this.headers })
  }

  formatDate(date) {
    let splitDate = date.split('/')
    let formattedDate = (splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]) + '-' + splitDate[2];
   
    return formattedDate;
  }

  filterByDate(date) {
    return this.transactionsObs.pipe(
      take(2),
      map((arr) => {
        return arr.filter((transaction) => this.formatDate(transaction.date) === date)
      })
    )
  }
}
