import { Component, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { BudgetService } from 'src/app/services/budgets/budget.service';
import { take } from 'rxjs/operators';
import { BudgetData } from 'src/app/interfaces/budgetData';

@Component({
  selector: 'app-budget-datum-input',
  templateUrl: './budget-datum-input.component.html',
  styleUrls: ['./budget-datum-input.component.css']
})
export class BudgetDatumInputComponent {
  constructor(private currencyPipe: CurrencyPipe, private budgetService: BudgetService) {}

  @Input() months;
  @Input() category;
  @Input() subcategory;
  @Input() index;
  @Input() user;
  output: String[] | number[] = [];
  public invalid: boolean = false;

  stripFormatting(input: String) {
    return input.replace((/(\$|\,)/g), '');
  }

  formatToCurrency(index, id, input) {
    if (input !== '' && input !== null && !input.includes('$')) {
      try {
        this.output[id] = this.currencyPipe.transform(input, 'USD', "symbol-narrow").toString();
      } catch (InvalidPipeArgument) {
        if(this.months[index].data[id] !== undefined) {
          this.output[id] = (this.months[index].data[id].amount === null) ?
          '' : this.currencyPipe.transform(this.months[index].data[id].amount, 'USD', "symbol-narrow").toString();
        } else {
          this.output[id] = '';
        }
      }
    } else {
      this.output[id] = '';
    }

    return this.output[id];
  }

  getAmount(amount) {
    if (amount !== undefined) {
      return amount;
    }
  }

  validateInput(input) { 
    if(input.search((/(\$|\,)/g)) !== 0 && input !== undefined && input !== '') {
      //console.log(input)
      return input.search(/^[0-9]{0,12}([\.][0-9]{2,2})?$/g) === 0;
    }
  }

  processBudgetDatum(cat_id, sub_id, user_id, amount = '0', index) {
    if (this.months[index].data[sub_id] !== undefined) {
        //console.log('1-1');
      if ((Number(this.months[index].data[sub_id].amount).valueOf() !== Number(amount).valueOf()) && (amount.search(/^[0-9]{0,12}([\.][0-9]{2,2})?$/g) === 0)) {
        for(let i = 0; i < 3; i++) {
          this.months[i].onchange = true; //still dont like this
        }
        //console.log('1-2');
        amount = (amount === '') ? '0' : amount; // fixes InvalidPipeArgument, if input is empty after having a value.

        const payload = { budget_datum: {'categories_id': cat_id, 'subcategories_id': sub_id, 'user_id': user_id, 'date':
        this.months[index].date, 'amount': amount } };

        this.budgetService.updateBudgetData(payload, this.months[index].data[sub_id].id).pipe(take(1)).subscribe(
          () => {
            for(let i = 0; i < 3; i++) {
              this.months[i].onchange = false; //still dont like this
            }
          }
        );

        this.months[index].totals[cat_id] =
          this.months[index].totals[cat_id] - Number(this.months[index].data[sub_id].amount) + Number(amount).valueOf();
          this.months[index].data[sub_id].amount = Number(amount).valueOf(); // updates this.months to reflect updated amount.  
      }

    } else if (this.months[index].data[sub_id] === undefined && amount !== '') {
      //console.log('1-3');
      for(let i = 0; i < 3; i++) {
        this.months[i].onchange = true; //still dont like this
      }
      const payload = { 'budget_datum' : { 'categories_id': cat_id, 'subcategories_id': sub_id, 'user_id': user_id, 'date':
            this.months[index].date, 'amount': +amount }};

      if(amount.search(/^[0-9]{0,12}([\.][0-9]{2,2})?$/g) === 0) {
        this.budgetService.addBudgetData(payload).pipe(take(1)).subscribe(
          (create: BudgetData) => {
            this.months[index].data[sub_id] = create;
            this.budgetService.updateBudgetDataObject('new', create)
            for(let i = 0; i < 3; i++) {
              this.months[i].onchange = false; //still dont like this
            }

          }
        );
        this.months[index].totals[cat_id] = (this.months[index].totals[cat_id] === undefined) ?
          (0 + Number(amount).valueOf()) :  this.months[index].totals[cat_id] + Number(amount).valueOf();  
      }
    } else {
      //console.log('cancelled');
    }

  }

}
