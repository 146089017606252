import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component';

@Component({
  selector: 'app-accounts-sidebar',
  templateUrl: './accounts-sidebar.component.html',
  styleUrls: ['./accounts-sidebar.component.css']
})
export class AccountsSidebarComponent extends SidebarComponent implements OnInit {
  public showAccounts: Object = new Object({checking: true, savings: true, credit_cards: true, closed: true});  
  
  @Input() accountsObs;
  @Input() totalsObs;

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
  }

}
