import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Accounts } from 'src/app/interfaces/accounts';

@Injectable()

export class AccountService {
  public accountsSource: BehaviorSubject<Accounts[]> = new BehaviorSubject<Accounts[]>([]);
  public accountsObs: Observable<Accounts[]> = this.accountsSource.asObservable();
  public accounts: Accounts[] = [];
  private headers: HttpHeaders = new HttpHeaders({ 'content-type': 'application/json', 'accept': 'application/json' }); 
  private base_url: String = "https://api.mrbudget.io/api/v1/accounts";

  constructor(private http: HttpClient) {}

  /**
   *   updateAccountsObject()
   * @param val 
   * Calls next on the accountsSource Subject and updates it with either a 
   * new result provided by the subscription observable, or a value provided by you.  
   */
  updateAccountsObject(key, val?) {
    // console.log(val)

    switch(key) {
      case('init'): 
        this.getAccounts().subscribe((accounts: Accounts[]) => {
          //console.log(accounts)
          this.accountsSource.next(accounts);
          this.accounts = [];
          this.accounts.push(...accounts);
          // console.log('initial subscription - accounts')
        });
      break;
      case('local'):
        this.accountsSource.subscribe((accounts) => {
          //console.log(accounts)
          this.accounts = accounts;
        });
        // console.log('local - accounts')
        break;
      case('empty'):
        this.accounts = [];
        this.accountsSource.next(this.accounts);
        // console.log('empty this.accounts - accounts')
      break;
      case('one'):
        this.accounts.push(val)
        this.accountsSource.next(this.accounts);
        // console.log('single push to this.account - accounts')
      break;
      case('multi'):
        this.accounts = [];
        this.accounts.push(...val)
        this.accountsSource.next(this.accounts);
        // console.log('multi push to this.accounts - accounts')
      break;
    }
    this.accountsSource.next(this.accounts)
    // console.log(this.accounts)
  }

  getAccount(id) {
    let url = this.base_url + '/' + id

    return this.http.get(url, { headers: this.headers }).pipe(share())
  }

  getAccounts() {
    let url = this.base_url + '/'

    return this.http.get(url, { headers: this.headers }).pipe(share())
  }

  addAccount(budget_id, user_id, payload) {
    let url = this.base_url + '/'

    return this.http.post(url, { account: {...payload.value, user_id, budget_id}}, { headers: this.headers });
  }

  updateAccount(user_id, id, payload) {
    let url = this.base_url + '/'

    return this.http.put(url, payload, { headers: this.headers })
  }

  closeAccount(user_id, id, payload) {
    let url = this.base_url + '/'

    return this.http.put(url, payload, { headers: this.headers })
  }

}
