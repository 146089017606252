import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/users/user.service';
import { User } from '../../interfaces/user';
import { AccountService } from 'src/app/services/accounts/account.service';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { BudgetService } from 'src/app/services/budgets/budget.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public userService: UserService, public accountService: AccountService, private transactionsService: TransactionsService, private budgetService: BudgetService) {}

  logout() {
    this.accountService.updateAccountsObject('empty')
    this.transactionsService.transactionsSource.next([])
    this.budgetService.budgetDataSource.next([])
    
    localStorage.removeItem('access-token');
    localStorage.removeItem('uid');
    localStorage.removeItem('client');
    this.userService.user = null;
    this.userService.validate();
  }

  getUser(): User {
    return this.userService.user;
  }

  ngOnInit() {}

}
