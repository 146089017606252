import { Component, OnInit, Input, OnChanges, SimpleChanges, Renderer2, ElementRef, QueryList, ViewChildren, ViewChild, ChangeDetectorRef, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { Subcategories } from 'src/app/interfaces/subcategories';
import { Categories } from 'src/app/interfaces/categories';
import { CheckInOutValues } from 'src/app/core/transactions/transactions-main-ui/transaction-validator.validator';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { Transactions } from 'src/app/interfaces/transactions';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.css'],
  providers: [CurrencyPipe]
})
export class TransactionsTableComponent implements OnInit, OnChanges, AfterViewInit {

  constructor(private currencyPipe: CurrencyPipe, private transactionsService: TransactionsService, private renderer: Renderer2, private formbuilder: FormBuilder, private cdr: ChangeDetectorRef) {}

  @Input() activeAccountID;
  @Input() viewAll;
  @Input() formGroupID;
  @Input() categories;
  @Input() subcategories;
  @Input() transactions: Transactions[];
  @Input() accounts;
  @Input() newRow;
  @ViewChildren('rows') rows: QueryList<ElementRef>;
  @ViewChild('transactionRows', {static: true}) transactionRows: ElementRef;
  @ViewChild('checkAllInput', {static: false}) checkAllInput: ElementRef;

  editTransactionForm: FormGroup;
  edit: boolean[] = [];
  activeEditID: number = 0;
  currentDate: String = new Date().toLocaleDateString();
  selectedRows: number[] = [];

  ngOnInit() {
    /* initialize form */
    this.editTransactionForm = this.formbuilder.group({
      date: new FormControl(this.currentDate.toString(), Validators.required),
      payee: new FormControl('', Validators.required),
      subcategories_id: new FormControl('', Validators.required),
      account_id: new FormControl('', Validators.required),
      memo: new FormControl(''),
      in: new FormControl(''), 
      out: new FormControl('')
    },
    {
      validator: CheckInOutValues("in", "out")
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.transactions !== undefined) {
      if(changes.transactions.currentValue !== changes.transactions.previousValue) {
        for(let t of this.transactions) {
          if(this.edit[t.id] === undefined) {
            this.edit[t.id] = false;
          }
        }
      }
    }

    if(this.rows !== undefined) {
      let editRow;

      this.rows.changes.pipe(
        take(1),
        map((row) => {
          if(this.rows !== undefined) {
            editRow = this.rows.filter((row) => +row.nativeElement.defaultValue === this.activeEditID);

            if(editRow.length === 0) {
              this.rows.last.nativeElement.scrollIntoView();
            } else {
              console.log(editRow)
              editRow[0].nativeElement.scrollIntoView();
              setTimeout(() => {this.activeEditID = 0}, 0)            
            }
          }
        })
      ).subscribe();
    }
  }

  cancelEdit(value) {
    return this.edit[value] = false;
  }

  ngAfterViewInit() {
    this.transactionRows.nativeElement.scrollTo(0, this.transactionRows.nativeElement.scrollHeight);
  }

  deleteRows() {
    this.selectedRows.map((id) => {
      this.transactionsService.deleteTransactions(id).subscribe(
        () => null,
        () => null,
        () => { 
          let index = this.transactions.findIndex((transaction) => transaction.id === id)

          this.transactions.splice(index, 1)
          this.transactionsService.updateTransactionsObject('multi', this.transactions)
          this.selectedRows = []
          this.renderer.setProperty(this.checkAllInput.nativeElement, 'checked', false)
        });
    })
  }

  onResize(event) {
    this.checkClientHeight();
  }

  setEditFormControls(form, transaction) {
    this.editTransactionForm.setValue({date: this.currentDate, payee: transaction.payee, subcategories_id: transaction.subcategories_id, account_id: transaction.account_id, memo: transaction.memo, in: transaction.in, out: transaction.out})
  }

  selectRow(rowID) {
    if(rowID === -1) {
      if(this.selectedRows.length > 0) {
        this.selectedRows = []
      }

      this.rows.map((row) => {
        if(this.checkAllInput.nativeElement.value === 'true') {
          this.renderer.setProperty(row.nativeElement, 'checked', false)
          this.selectedRows = []
        } else {
          this.renderer.setProperty(row.nativeElement, 'checked', true);
          this.selectedRows.push(+row.nativeElement.defaultValue);
        }
      })

      return (this.checkAllInput.nativeElement.value === 'true') ? this.renderer.setProperty(this.checkAllInput.nativeElement, 'value', 'false') : this.renderer.setProperty(this.checkAllInput.nativeElement, 'value', 'true')
    } else {
      let row = this.rows.filter((row) => +row.nativeElement.defaultValue === rowID)[0]
      let id = this.selectedRows.findIndex((ID) => rowID === ID);
  
      if(row.nativeElement.checked) {
        this.renderer.setProperty(row.nativeElement, 'checked', true)
        this.selectedRows.push(rowID)
      } else {
        this.renderer.setProperty(row.nativeElement, 'checked', false)
        this.selectedRows.splice(id, 1)
      }
    }
  }

  getClientHeight() {
    return window.innerHeight;
  }

  trimMemo(memo: String) {
    if(memo !== null && memo.length > 18) {
      return memo.substr(0,22) + '...'
    } else {
      return memo
    }
  }

  checkClientHeight() {
    return window.innerHeight.toString();
  }

  formatToCurrency(input) {
    return (input !== null) ? this.currencyPipe.transform(input, "USD", "symbol-narrow").toString() : '';
  }

  getCatfromSubcategoryID(subcategory_id) {
    if(subcategory_id === -2) 
      return "Starting Balance"

    if(subcategory_id === -1)
      return "Income ";

    if(this.subcategories === undefined)
      return;

    let category_id = this.subcategories.filter((subcategory: Subcategories) => subcategory_id === subcategory.id).map(({categories_id}) => { return categories_id })[0]

    if(category_id === undefined)
      return;

    let category = this.categories.filter((category: Categories) => category_id === category.id)[0]

    return (category === undefined) ? '' : category.name;
  }

  getSubcategory(id: number, income_date: string) {
    if(income_date !== null) {
      let month = +income_date.split('-')[0];
      let year = +income_date.split('-')[1];
      const monthsHumanized = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      return monthsHumanized[month-1] + ' ' + year;
    } else {
      if(this.subcategories === undefined)
        return;
        
      let subcategory = this.subcategories.filter((subcategory: Subcategories) => subcategory.id === id)
      
      return subcategory.map((subcategory: Subcategories) => {
        if(subcategory.hidden === true) {
          return subcategory.name + " *"
        } else {
          return subcategory.name
        }
      })
    }
  }
}
