import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../../interfaces/user';

@Injectable()
export class UserService {
  private API_ROOT = 'https://api.mrbudget.io/auth';
  private headers: HttpHeaders = new HttpHeaders({ 'Content-type': 'application/json', 'Accept': 'application/json' });
  public user: User = new User();
  public errors: Object;

  

  constructor(private http: HttpClient, private router: Router) {}

  public setUser(user) {
    return this.user = user.body.data;
  }

  public _getUser() {
    return this.user;
  }

  public getUser(validate?: string) {
    const url: string = this.API_ROOT + '/validate_token';

    return this.http.get(url, { headers: this.headers, observe: 'response' });
  }

  public setErrors(err: Object) {
    this.errors = err;
  } 

  public getErrors() {
    return this.errors;
  }

  public resetErrors() {
    return this.errors = {};
  }

  public validate() {
    if (this._getUser() === null) {
      return this.router.navigate(['login'])
    } else {
      return true;
    }
  }

  public login(email: string, password: string) {
    const url: string = this.API_ROOT + '/sign_in';

    return this.http.post(url, { email: email, password: password }, { headers: this.headers, observe: 'response' });
  }

  public register(email: string, nickname: string, password: string, password_confirmation: string) {
    const url: string = this.API_ROOT;

    return this.http.post(url, 
      { email: email, nickname: nickname, password: password, password_confirmation: password_confirmation }, { headers: this.headers }).pipe(map(res => res));
  }

  ngOnInit() {
    this.setErrors(undefined);
    this.setUser(this.getUser().subscribe((_user) => _user));
  }

}
