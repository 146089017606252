import { FormGroup } from "@angular/forms";

// To validate password and confirm password
export function CheckInOutValues(inflow: string, outflow: string) {  
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[inflow];
    const matchingControl = formGroup.controls[outflow];

    if (matchingControl.errors && !matchingControl.errors.oneNotNull)
      return;

    
    if (String(control.value).valueOf().search((/^[0-9]{0,12}([\.][0-9]{2,2})?$/g)) !== 0) {
      control.setErrors({ oneNotNull: true });
    } else if (String(matchingControl.value).valueOf().search((/^[0-9]{0,12}([\.][0-9]{2,2})?$/g)) !== 0) {
      matchingControl.setErrors({ oneNotNull: true });
    } else if (control.value === '' && matchingControl.value === '') {
      matchingControl.setErrors({ oneNotNull: true });
      control.setErrors({ oneNotNull: true });
    } else if(control.value.length > 0 && matchingControl.value.length > 0)  {
      matchingControl.setErrors({ oneNotNull: true });
      control.setErrors({ oneNotNull: true });
    } else {
      matchingControl.setErrors(null);
      control.setErrors(null)
    }
    
    
  };
}