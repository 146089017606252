import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ViewChild, asNativeElements } from '@angular/core';
import { BudgetMainUIComponent } from '../budget-main-ui.component'
import { User } from 'src/app/interfaces/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take, takeUntil, map, last } from 'rxjs/operators';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

@Component({
  selector: 'app-budget-view-desktop',
  templateUrl: './budget-view-desktop.component.html',
  styleUrls: ['./budget-view-desktop.component.css']
})
export class BudgetViewDesktopComponent extends BudgetMainUIComponent implements OnInit {
  @Input() user: User;
  @ViewChild('moveableSection', {static: false}) moveableSection: ElementRef;
  @ViewChildren('moveableCategory') moveableCategory: QueryList<ElementRef>

  ngOnInit() {
    this.accountsService.updateAccountsObject('init');
    this.transactionsService.updateTransactionsObject('init')
    this.budgetService.updateSubcategoriesObject('init');
    this.budgetService.updateCategoriesObject('init');
    this.budgetService.updateBudgetDataObject();
    
    this.setBudgetTableLimit(this.getClientWidth);
    this.setCategories();
    this.generateMonths(true);

    this.updateCategoryForm = new FormGroup({
      editCategoryName: new FormControl("", Validators.compose([
        Validators.required,
        Validators.nullValidator,
        Validators.minLength(1),
      ]))
  });
  this.addCategoryForm = new FormGroup({
    addCategoryName: new FormControl("", Validators.compose([
      Validators.required,
      Validators.nullValidator,
      Validators.minLength(1),
    ]))
  });
  this.updateSubcategoryForm = new FormGroup({
    editSubcategoryName: new FormControl("", Validators.compose([
      Validators.required,
      Validators.nullValidator,
      Validators.minLength(1),
    ]))
  });
  this.addSubcategoryForm = new FormGroup({
    addSubcategoryName: new FormControl("", Validators.compose([
      Validators.required,
      Validators.nullValidator,
      Validators.minLength(1),
    ]))
  });
  }

  moveRowObject() {
    const magicListItem = document.getElementById('magicListItem')
    const subcategories = document.getElementsByClassName('subcategory-removable')
    const mouseDown$ = fromEvent(this.moveableSection.nativeElement, 'mousedown')
    const mouseMove$ = fromEvent(document, 'mousemove')
    const mouseUp$ = fromEvent(document, 'mouseup')
    let first = 0;
    let categoryMoving;
    let categoryMovingX;
    let categoryMovingY;
    let selectedCategory;
    let categoryMovingTopX;
    let categoryMovingLeftY;
    let hiddenSubcategoryDiffX
    let hiddenSubcategoryDiffY
    let selectedCategoryOld;
    let permanentText;
    let previousText

    mouseDown$.pipe(take(1)).subscribe((el: MouseEvent) => {
      console.log(this.updateCategoryForm)
      categoryMoving = el.target
      categoryMovingX = el.clientX;
      categoryMovingY = el.clientY;
      categoryMovingTopX = categoryMoving.getBoundingClientRect().left;
      categoryMovingLeftY = categoryMoving.getBoundingClientRect().top;
      permanentText = categoryMoving.children[0].innerText
    });

    mouseMove$.pipe(takeUntil(mouseUp$)).subscribe(
      (el: MouseEvent) => {

        window.scrollTo(0, 0) // scroll to the top of the window to prevent issues with XY values
        document.getSelection().removeAllRanges(); //deselection to prevent highlighting areas when moving a category

        if(first === 0) {
          first = 1;
          for(let i = 0; i < subcategories.length; i++) {
            this.renderer.addClass(subcategories.item(i), 'hide-subcategories');
            setTimeout(() => {  this.renderer.setStyle(subcategories.item(i), 'display', 'none') }, 300)
          }
          setTimeout(() => {
            hiddenSubcategoryDiffX = categoryMoving.getBoundingClientRect().left - categoryMovingTopX;
            hiddenSubcategoryDiffY = categoryMoving.getBoundingClientRect().top - categoryMovingLeftY; 
          }, 301);
        }

        const div = this.renderer.createElement('td');
        const text = this.renderer.createText(categoryMoving.children[0].innerText);

        this.moveableCategory.map(
          (category: ElementRef) => {
            let startX;
            if(selectedCategory === category.nativeElement)
                return;

            if(category.nativeElement.id === categoryMoving.id) {
              console.log("if: ", categoryMoving.children[0].innerText)
              startX = category;
            } else {

              let currentY = category.nativeElement.getBoundingClientRect().y + 38;

              if(el.clientY < currentY && el.clientY > currentY - 38) {
                /** 
                 * If the mouse cursor (or category being moved) is over a new category this is where we adjust the UI to show where the moving category will end up.
                 */
                selectedCategory = category.nativeElement;

                if(categoryMoving.children[0].innerText === permanentText) {
                  /*  */
                  console.log('IF:')
                  console.log("categoryMoving", categoryMoving.children[0].innerText)
                  categoryMoving.children[0].innerText = selectedCategory.children[0].innerText;
                  if(previousText !== undefined) {
                    console.log("selectedCategory", selectedCategory.children[0].innerText)
                    selectedCategory.children[0].innerText = previousText;
                  }
                } else {
                  /*  */
                  console.log('ELSE:')
                  console.log("categoryMoving", categoryMoving.children[0].innerText)
                  categoryMoving.children[0].innerText = previousText;
                  console.log("selectedCategoryOld", selectedCategoryOld.children[0].innerText)
                  //selectedCategoryOld = selectedCategory
                  console.log("selectedCategory", selectedCategory.children[0].innerText)
                  //selectedCategory.children[0].innerText = permanentText; 
                }

                /* 
                console.log("else: ", categoryMoving.children[0].innerText)

                console.log('1')
                console.log("categoryMoving", categoryMoving.children[0].innerText)
                categoryMoving.children[0].innerText = selectedCategory.children[0].innerText
                console.log("selectedCategory", selectedCategory.children[0].innerText)
                //selectedCategoryOld.children[0].innerText = selectedCategory.children[0].innerText

                console.log('2')
                if(selectedCategoryOld !== undefined) {
                  console.log("selectedCategoryOld", selectedCategoryOld.children[0].innerText)
                  selectedCategoryOld.children[0].innerText = selectedCategory.children[0].innerText;
                  console.log("selectedCategoryOld", selectedCategoryOld.children[0].innerText)
                }

                if(selectedCategory !== categoryMoving && selectedCategoryOld !== undefined) {
                  selectedCategory.children[0].innerText = permanentText;
                  console.log("selectedCategoryOld", selectedCategoryOld.children[0].innerText)
                  //categoryMoving.children[0].innerText = selectedCategoryOld.children[0].innerText
                  console.log("categoryMoving", categoryMoving.children[0].innerText)
                }
                 */

                /**
                if(permanentText === previousText) {
                    categoryMoving.children[0].innerText = permanentText;
                    console.log("previousText", previousText)
                    console.log("permanentText", permanentText)
                }

                if(previousText !== undefined && permanentText !== previousText) {
                  console.log('2')
                  //console.log("categoryMoving", categoryMoving.children[0].inner)
                  categoryMoving.children[0].innerText = selectedCategory.children[0].innerText;
                  //console.log("selectedCategory", selectedCategory.children[0].innerText)
                  console.log("selectedCategoryOld", selectedCategoryOld)
                  
                  selectedCategoryOld = previousText;
                  selectedCategory.children[0].innerText = selectedCategoryOld;
                  //console.log("selectedCategory", selectedCategory.children[0].innerText)
                } 
                */

                // if(selectedCategoryID !== null && selectedCategoryIDOld !== selectedCategoryID) {
                //   console.log(selectedCategory)
                //   selectedCategoryIDOld = selectedCategoryID
                //   categoryMoving.children[0].innerText = selectedCategoryOld.children[0].innerText // changes the moving categories inner text
                // } else {              
                //   selectedCategoryID = category.nativeElement.id;

                //     if(selectedCategoryOld !== undefined)
                //       console.log("selectedCategoryOld", selectedCategoryOld.children[0].innerText)
                //       selectedCategory.children[0].innerText = oldText
                //       console.log("selectedCategory " + selectedCategory.children[0].innerText);
                //       console.log("-----")
                    /* if(document.getElementsByClassName("temp-category").length === 0) {
                      this.renderer.appendChild(selectedCategory.parentNode.parentNode, div)
                      this.renderer.appendChild(div, text)
                      this.renderer.addClass(div, 'col-sm-12')
                      this.renderer.addClass(div, 'temp-category')
                    } */
                    //categoryMoving.chidlren[0].innerText = selectedCategory.children[0].innerText;
                  //}

                //if(selectedCategoryIDOld !== selectedCategoryID && selectedCategoryIDOld !== undefined)  {
                //selectedCategoryOld = selectedCategory
                //oldTr = document.getElementById(selectedCategoryIDOld);
                //oldTds = document.getElementsByClassName("temp-category");
                
                //this.renderer.removeChild(oldTds[0].parentNode, oldTds[0])
                //se
              }
            }
          }
        );
      }
    );
  
    mouseUp$.pipe(take(1)).subscribe((el: MouseEvent) => {
      first = 0;
      setTimeout(() => {
        for(let i = 0; i < subcategories.length; i++) {
          selectedCategoryOld = selectedCategory
          this.renderer.removeStyle(subcategories.item(i), 'display');
          this.renderer.removeClass(subcategories.item(i), 'hide-subcategories');
          let lastCategoryActive = this.moveableCategory.filter((category) => category.nativeElement.id === selectedCategory.id)[0].nativeElement
          //categoryMoving.children[0].innerText = oldText
          this.renderer.removeStyle(lastCategoryActive, 'background')
        } 
      }, 100)
      //this.renderer.removeChild(oldTds[0].parentNode, oldTds[0])
      categoryMoving.children[0].innerText = permanentText;
      this.renderer.removeClass(categoryMoving, 'category-moveable')
      this.renderer.removeStyle(categoryMoving, 'left')
      this.renderer.removeStyle(categoryMoving, 'top')
      console.log(el)
    })
  }

}
