import { Component, Input } from '@angular/core';
import { BudgetMainUIComponent } from '../budget-main-ui.component';
import { User } from 'src/app/interfaces/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-budget-view-mobile',
  templateUrl: './budget-view-mobile.component.html',
  styleUrls: ['./budget-view-mobile.component.css']
})
export class BudgetViewMobileComponent extends BudgetMainUIComponent {
  @Input() user: User;

  ngOnInit() {
    this.setCategories();
    this.setBudgetTableLimit(this.getClientWidth);
    this.generateMonths(true);
    this.accountsService.updateAccountsObject('init');
    this.transactionsService.updateTransactionsObject('init');
    this.updateCategoryForm = new FormGroup({
      editCategoryName: new FormControl("", Validators.compose([
        Validators.required,
        Validators.nullValidator,
        Validators.minLength(1),
      ]))
  });
  this.addCategoryForm = new FormGroup({
    addCategoryName: new FormControl("", Validators.compose([
      Validators.required,
      Validators.nullValidator,
      Validators.minLength(1),
    ]))
  });
  this.updateSubcategoryForm = new FormGroup({
    editSubcategoryName: new FormControl("", Validators.compose([
      Validators.required,
      Validators.nullValidator,
      Validators.minLength(1),
    ]))
  });
  this.addSubcategoryForm = new FormGroup({
    addSubcategoryName: new FormControl("", Validators.compose([
      Validators.required,
      Validators.nullValidator,
      Validators.minLength(1),
    ]))
  });
  }

}
