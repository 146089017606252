import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { BudgetService } from 'src/app/services/budgets/budget.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { AccountService } from 'src/app/services/accounts/account.service';
import { Title } from '@angular/platform-browser';
import { CheckInOutValues } from "./transaction-validator.validator";

@Component({
  selector: 'app-main-transactions',
  templateUrl: './transactions-main-ui.component.html',
  styleUrls: ['./transactions-main-ui.component.css'],
  providers: [CurrencyPipe]
})
export class TransactionsMainUIComponent implements OnInit {
  
  constructor(private formbuilder: FormBuilder, private title: Title, private budgetService: BudgetService, 
              public transactionsService: TransactionsService, private accountsService: AccountService, public renderer: Renderer2, public el: ElementRef) {}

  newTransactionForm: FormGroup;
  currentDate: String = new Date().toLocaleDateString();
  editing: boolean = false;

  getClientHeight() {
    return window.innerHeight;
  }

  ngOnInit() {
    /* initialize form */
    this.newTransactionForm = this.formbuilder.group({
      date: new FormControl(this.currentDate.toString(), Validators.required),
      payee: new FormControl('', Validators.required),
      subcategories_id: new FormControl('', Validators.required),
      account_id: new FormControl('', Validators.required),
      memo: new FormControl(''),
      in: new FormControl(''), 
      out: new FormControl('')
    },
    {
      validator: CheckInOutValues("in", "out")
    });

    /* initialize observables */
    this.budgetService.updateSubcategoriesObject('init');
    this.budgetService.updateCategoriesObject('init');
    this.budgetService.updateBudgetDataObject('init')
    this.accountsService.updateAccountsObject('init');
    this.transactionsService.updateTransactionsObject('init');
    
    this.title.setTitle('MrBudget –– Transactions')

  }

}
