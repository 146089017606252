import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { User } from '../../../../interfaces/user';
import { Budgets } from '../../../../interfaces/budgets';
import { BudgetMainUIComponent } from '../budget-main-ui.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-budget-new',
  templateUrl: './budget-new.component.html',
  styleUrls: ['./budget-new.component.css']
})
export class BudgetNewComponent extends BudgetMainUIComponent implements OnInit {

  @Input() user: User;
  new_budgets: Budgets[] = [new Budgets()];
  budget_name: string;
  currency: string;
  currency_format: string;
  newBudgetForm: FormGroup;


  createBudget(payload, setDefault?) {
    if (this.newBudgetForm.status === "INVALID")
      return;

    this.budgetService.createBudget(payload).subscribe((data: Budgets) => {
      this.new_budgets.push(data);
      //if (setDefault) {
        this.budgetService.setDefault(data.id, data.user_id).subscribe(
          () => console.log("default set")
        );
      //}
    });
  }

  budgetExists() {
    return this.budget != null;
  }

  setAccountTypeFormValue(currency_format, currency) {
    this.newBudgetForm.patchValue({currency_format: currency_format, currency: currency})
    console.log(this.newBudgetForm.value)
  }

  ngOnInit() {
    console.log(this.budgets)

    this.newBudgetForm = new FormGroup({
      name: new FormControl('', Validators.required),
      currency_format: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required)
    })

  }

}
