import { BudgetData } from './budgetData';
import { Observable } from 'rxjs';
import { Transactions } from './transactions';

export class Month {
    public date: string;
    public humanizedDate: string;
    public data: BudgetData[] = [];
    public totals: number[] = [];
    public trxTotals: Observable<Transactions[]>;
    public loading: boolean = false;
    public onchange: boolean = false;
}