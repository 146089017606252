import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AccountsComponent } from '../accounts.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Accounts } from 'src/app/interfaces/accounts';
import { Transactions } from 'src/app/interfaces/transactions';
import { take } from 'rxjs/operators';
import { Categories } from 'src/app/interfaces/categories';
import { Subcategories } from 'src/app/interfaces/subcategories';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-accounts-form',
  templateUrl: './accounts-form.component.html',
  styleUrls: ['./accounts-form.component.css']
})
export class AccountsFormComponent extends AccountsComponent implements OnInit, OnChanges {
  @Input() accounts: Accounts[]; 
  @Input() transactions: Transactions[]; 
  @Input() categories: Categories[]; 
  @Input() subcategories: Subcategories[]; 

  ngOnInit() {
    this.newAccountForm = new FormGroup({
      name: new FormControl('', Validators.required),
      typeof: new FormControl('', Validators.required),
      balance: new FormControl('', Validators.required)
    });  
    this.editAccountForm = new FormGroup({
      name: new FormControl('', Validators.required),
      typeof: new FormControl('', Validators.required),
      balance: new FormControl('', Validators.required)
    });  
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
  }

  getUser() {
    return this.userService.user;
  }

  setAccountTypeFormValue(val) {
    return this.newAccountForm.patchValue({'typeof': val})
  }

  addAccount(budget_id, user_id, payload) {
    if (this.newAccountForm.status === "INVALID")
      return;

    return this.accountsService.addAccount(budget_id, user_id, payload).pipe(take(1)).subscribe(
      (account: Accounts) => {
        let subcategory_payload;
        let main_category = this.categories.filter((category) => category.name === 'Pre-MrB')
        let category_payload = { name: 'Pre-MrB', budget_id: -1 }

        this.accountsService.updateAccountsObject('one', account)

        let positive_balance = payload.controls['balance'].value >= 0;

        console.log(main_category)

        if(positive_balance) {
          console.log('positive balance')
          let transaction_payload = { date: new Date().toLocaleDateString(), payee: 'Starting Balance', 
          subcategories_id: -2, account_id: account.id, in: +payload.controls['balance'].value}

          this.transactionsService.addTransaction(transaction_payload).pipe(take(1)).subscribe(
            (transaction) => {
              this.transactionsService.updateTransactionsObject('one', transaction)
            }
          );
        } else if(main_category.length > 0) {
          console.log('negative balance - create category and subcategory')
          /** */
          subcategory_payload = { name: account.name, categories_id: main_category[0].id }

          this.budgetService.addSubcategory(subcategory_payload).pipe(take(1)).subscribe(
            (subcategory: Subcategories) => {
              let subcategory_sort_payload =  {subcategory_sort_order: main_category[0].subcategory_sort_order + ';' + subcategory.id} /** */
              let transaction_payload = { date: new Date().toLocaleDateString(), payee: 'Starting Balance', 
                                          subcategories_id: subcategory.id, account_id: account.id, out: +payload.controls['balance'].value.split('-')[1]}

              this.budgetService.updateCategory(subcategory.categories_id, subcategory_sort_payload).pipe(take(1)).subscribe()
              this.transactionsService.addTransaction(transaction_payload).pipe(take(1)).subscribe(
                (transaction) => {
                  this.transactionsService.updateTransactionsObject('one', transaction)
                }
              );
            }
          );
          /** */
        } else {
          console.log('negative balance - create subcategory only')
          this.budgetService.addCategory(category_payload).pipe(take(1)).subscribe(
            (category: Categories) => {
              /** */
              subcategory_payload = { name: account.name, categories_id: category.id } /** */
  
              this.budgetService.addSubcategory(subcategory_payload).pipe(take(1)).subscribe(
                (subcategory: Subcategories) => {
                  let subcategory_sort_payload = {subcategory_sort_order: ';'+subcategory.id } /** */
                  let transaction_payload = { date: new Date().toLocaleDateString(), payee: 'Starting Balance', 
                                              subcategories_id: subcategory.id, account_id: account.id, out: +payload.controls['balance'].value.split('-')[1] }
                                            
                  this.budgetService.updateCategory(subcategory.categories_id, subcategory_sort_payload).pipe(take(1)).subscribe()
                  this.transactionsService.addTransaction(transaction_payload).pipe(take(1)).subscribe(
                    (transaction) => {
                      this.transactionsService.updateTransactionsObject('one', transaction)
                    }
                  );
                }
              );
              /** */
            }
          )
          
        }
        

        
      }
    );
  }

}
